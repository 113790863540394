import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link, useParams} from "react-router-dom";
import {getEnrollInfo, deleteEnroll, sendEnroll} from "../../dao";
import React, { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {
  faAngleDown,
  faAngleUp,
  faCircleChevronLeft,
  faHotel,
  faInfoCircle,
  faLightbulb,
  faQuestionCircle,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {
  classDict,
  getClassName,
  handleErrorLoading,
  handleErrorSubmit,
  parseDateTime,
  parseDateTimeSec,
  preselectClass,
  setBrowserTabText,
  siCardArrayDict
} from "../../helpers/Functions";
import {isMobileDevice} from "../../helpers/Device";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import {HomeClub, InterestBasicOptions, PunchingOptions, Theme} from "../../helpers/Constants";
import Loader from "../../components/overlays/Loader";
import Alert from "react-bootstrap/Alert";
import FormField from "../../components/form/FormField";
import EnrollCamp from "./EnrollCamp";
import EventDate from "../../components/parts/EventDate";
import Modal from "react-bootstrap/Modal";
import {labels} from "../../themeLabels";
import Table from "react-bootstrap/Table";
import FormSelectDict from "../../components/form/FormSelectDict";
import OfferOrderSection from "../../components/parts/OfferOrderSection";
import FormEnrollTransport from "../../components/form/FormEnrollTransport";
import FormSelectArray from "../../components/form/FormSelectArray";

const Enroll = () => {
  const { eventId } = useParams();
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [si, setSi] = useState("");
  const [siNumber, setSiNumber] = useState("");
  const [classId, setClassId] = useState("");
  const [note, setNote] = useState("");
  const [clubNote, setClubNote] = useState("");
  const [carCapacity, setCarCapacity] = useState(null);
  const [carInfo, setCarInfo] = useState("");
  const [requestedStart, setRequestedStart] = useState("");
  const [transport, setTransport] = useState(0);
  const [accommodation, setAccommodation] = useState(0);
  const [dining, setDining] = useState(0);
  const [stagesSelected, setStagesSelected] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showServices, setShowServices] = useState(false);

  useEffect(() => {
    loadEnrollInfo();
  }, [userId, eventId]);

  function loadEnrollInfo(){
    setLoading(true);
    getEnrollInfo(eventId, userId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()})
      .then((response) => {
        setData(response);
        if (response?.user?.si_cards)
          setSi(Object.keys(siCardArrayDict(response?.user?.si_cards)[0])[0]);
        if (response?.user?.classes)
          setClassId(preselectClass(response?.user?.classes));
        if (response?.event?.transport === 1)
          setTransport(1);
        if (response?.event?.accommodation === 1)
          setAccommodation(1);
        if (response?.event?.dining === 1)
          setDining(1);
        if (response?.event?.stages_count > 0) {
          if (!response?.applied)
            setStagesSelected(Math.pow(2, response.event.stages_count + 1) - 1)
          else
            setStagesSelected(response?.detail?.stages)
        }
        if (response?.applied) {
          const source = response?.event?.type === 2 ? response.detail.submission : response.detail;
          if(source.rent_si){
            setSi('rent');
          }else{
            if(isSiCustom(response.user, source)){
              setSi('custom');
              setSiNumber(source.si_number);
            }else{
              setSi(source.si_number);
            }
          }
          setNote(source.note);
          setClubNote(response.detail.club_note);
          setRequestedStart(response.detail.start_request);
          setClassId(response.detail.class_id+"");
          setTransport(source.transport);
          setAccommodation(response.detail.accommodation);
          setDining(response.detail?.dining);
          if(source.car_capacity > 0){
            setCarCapacity(source.car_capacity);
          }
        } else {
          if (data?.event?.term < 0)
            setErrorRemote("Přihlášky nejsou otevřeny...");
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));

    function isSiCustom(user, detail){
      var res = true;
      user.si_cards.forEach((card)=> {
        if(detail.si_number === card.si_number){
          res = false;
        }
      });
      return res;
    }
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (si === 'custom' && siNumber === "") {
      return;
    }
    var rentSi = si === 'rent';
    var o_si;
    if(si === 'rent'){
      o_si = 0;
    }else if(si === 'custom'){
      o_si = siNumber;
    }else{
      o_si = si;
    }
    const mode = data.applied ? "PUT" : "POST";
    const formData = {
      "person" : data.user.id,
      "class_id" : classId,
      "class_name" : getClassName(classId, data.user.classes),
      "si" : o_si,
      "rent_si" : rentSi,
      "start_request" : requestedStart,
      "note" : note,
      "club_note" : clubNote,
      "car_capacity" : carCapacity,
      "car_info": carInfo,
      "transport" : transport,
      "accommodation" : accommodation,
      "dining" : dining,
      "stages": stagesSelected,
    }

    sendEnroll(eventId, data.user.id, formData, mode)
      .then((response) => {
        let inReserves = (data.event.entry_count > data.event.entry_limit) && data.event.entry_limit !== 0;
        if (response.ok) {
          (mode === "POST") ? setSuccess(inReserves ? "Přihláška mezi náhradníky přidána" : "Přihláška přidána") : setSuccess("Přihláška upravena");
          loadEnrollInfo();
        } else {
          setError(handleErrorSubmit(response, (mode === 'POST' ? "Nepodařilo se přihlásit" : "Nepodařilo se upravit přihlášku")));
        }
      })
  };

  function redirectToUserList(value){
    if (value==="*") {
      navigate("/akce/"+eventId,{state:{showEnrollList:true}});
    }
  }
  const CreatorEditorText = ({person, timestamp=null, label}) => {
    const labelText = label + ": ";
    if (person == null)
      return "";
    return <i>{labelText} {person.reg_number} – {person.full_name} {parseDateTimeSec(timestamp)}</i>;
  }

  const TopButtons = () => {
    return (<Col className="text-end">
      {data.event?.type === 1 && <a href={`/anonym/akce/${data.event?.id}/prihlaska`}>Jednorázová příhláška</a>}
      {(data.event?.type === 0 && data.event?.services > 0) && <Link to={`/akce/${eventId}/sluzby`}><Button className="mb-2 ms-3" variant="outline-primary"><FontAwesomeIcon icon={faHotel}/> Doplňkové služby</Button></Link>}
      <Link to={`/akce/${eventId}`}><Button variant="secondary" className="mb-2 ms-3"><FontAwesomeIcon icon={faCircleChevronLeft} /> Stránka akce</Button></Link>
    </Col>)
  }

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  if(data.event.type === 2)
    return <EnrollCamp data={data} eSi={si} eSiNumber={siNumber} eNote={note} eTransport={transport} eCarCapacity={carCapacity} />;

  const basicLimitReached = data.event?.entry_limit > 0 && data.event.entry_count >= data.event.entry_limit;
  const extendedLimitReached = data.event?.entry_limit > 0 && data.event.entry_count >= data.event.entry_limit + data.event.entry_reserves;

  setBrowserTabText('Přihláška | ' + data?.event?.title);
  return (
    <PageContainer background={getBackground()}>
      <Row>
        <Col xl={8} lg={12}>
          <h1>{data?.event?.term === 0 ? "Předběžná přihláška:" : "Přihláška:"} {data?.event?.title}</h1>
        </Col>
        <TopButtons/>
      </Row>
      <Row>
        <Col><p className="mb-2"><EventDate event={data?.event} /></p></Col>
        {data.event?.entry_limit > 0 && (isMobileDevice() ?
          <Col>{data.event.entry_count}/{data.event.entry_limit}+{data.event.entry_reserves}</Col> :
          <Col>
            <Row>
              <Col>Přihlášeno: {data.event.entry_count}</Col>
              <Col>Limit + náhradníci: {data.event.entry_limit} + {data.event.entry_reserves}</Col>
            </Row>
          </Col>)
        }
      </Row>
      {!data.applied ? (extendedLimitReached ? <Alert variant="danger">Kapacita naplněna!</Alert> : <></>) :
        (data?.detail?.reserve_idx > 0 ? <Alert variant="warning">Přihlášen/a jako náhradník #{data?.detail?.reserve_idx}</Alert> : <br/>)}
      {(data.applied && basicLimitReached) && <Alert variant="warning">
        <FontAwesomeIcon icon={faTriangleExclamation}/> Z důvodu omezení na straně Orisu je nutné změnu kategorie dělat jako odhlášku a novou přihlášku. Touto metodou bude tvoje přihláška přesunuta na poslední náhradnické místo. Pro bezpečnou změnu kategorie můžeš kontaktovat administrátora, ostatní změny lze provádět bez nutnosti odhlášky.</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <FormSelectDict label={"Uživatel"} value={"-"} setValue={redirectToUserList} disabled={data?.event?.term < 0} controlId={"user"}
                            options={{'-' : data?.user?.full_name + " - " + data?.user?.reg_number, '*': "Přihlásit ostatní"}} />
          </Col>
          <Col md>
            <FormSelectDict label={"Kategorie"} value={classId} setValue={setClassId} options={classDict(data?.user?.classes, data?.event?.type)}
                            disabled={data?.applied && (!data.detail?.delete_person || basicLimitReached)} controlId={"class"} />
          </Col>
          <Col md>
            <FormSelectArray label={<Row className="mb-2"><Col xs="auto">Čip:</Col><Col className="text-end">Ražení: {PunchingOptions[data?.event?.punching]}</Col></Row>}
                             options={siCardArrayDict(data?.user?.si_cards)} value={si} setValue={setSi} disabled={data?.applied && !data.detail?.edit_person}
                             controlId={"si"} />
            {si === "custom" && (<>
              <FormField label={"Číslo neuloženého čipu"} value={siNumber} setValue={setSiNumber} type={"number"} isInvalid={siNumber === ""}
                         disabled={data?.applied && !data.detail?.edit_person} controlId={"siUnsavedNumber"} />
              <i><FontAwesomeIcon icon={faLightbulb} color="gold"/> Své čipy si můžeš uložit v nastavení účtu</i>
            </>)
            }
          </Col>
        </Row>
        {data?.event?.stages_count > 0 && <StageSelection/>}
        {data?.event?.type === 0 ? <>
          <hr/>
          <Row>
            <Col>
              <FormField label={"Poznámka ORIS"} value={note} setValue={setNote} valueLimit={256}
                         disabled={data?.applied && !data.detail?.edit_person} controlId={"note"}/>
            </Col>
            <Col md>
              <FormField label={"Požadovaný start"} value={requestedStart} setValue={setRequestedStart} valueLimit={256}
                         disabled={(data?.applied && !data.detail?.edit_person) || data?.event?.start_request_forbidden} controlId={"startRequest"}/>
            </Col>
          </Row>
          <hr/>
        </> : <>{Theme !== "PGP" && <hr/>}</>}
        <FormEnrollTransport data={data} transport={transport} setTransport={setTransport} carCapacity={carCapacity} setCarCapacity={setCarCapacity}
                             carInfo={carInfo} setCarInfo={setCarInfo} />
        <Row>
          {labels.CondEventAccommodation && <Col md={4}>
            {data?.event?.club_options_notice ?
              <p><i>Pro přihlášení se ke společnému ubytování a dopravě (pokud jsou vypsány) použij přihlášku na závod předchozí den.</i></p> :
              <>
                <FormSelectDict label={"Klubové ubytování"} options={InterestBasicOptions} value={accommodation} setValue={setAccommodation} type={"number"}
                                disabled={data?.event?.accommodation !== 0 || (data?.applied && !data.detail?.edit_transport)} controlId={"accommodation"} />
                {data?.event?.accommodation_info !== "" && <p><FontAwesomeIcon icon={faInfoCircle} color="gray"/> <i>{data?.event?.accommodation_info}</i></p>}
              </>
            }
          </Col>}
          {(data?.event?.dining >= 0 && labels.CondEventDining) && <Col md={4}>
            <FormSelectDict label={"Klubové stravování"} options={InterestBasicOptions} value={dining} setValue={setDining} type={"number"}
                            disabled={data?.event?.dining !== 0 || (data?.applied && !data.detail?.edit_transport)} controlId={"dining"} />
            {data?.event?.dining_info !== "" && <p><FontAwesomeIcon icon={faInfoCircle} color="gray"/> <i>{data?.event?.dining_info}</i></p>}
          </Col>}
          <Col>
            <FormField label={"Poznámka " + HomeClub} value={clubNote} setValue={setClubNote} valueLimit={256} controlId={"clubNote"} />
          </Col>
        </Row>
        <Row>
          {data?.applied &&
            <Col md className="mb-2">
              <CreatorEditorText person={data.detail.created_by} timestamp={data.detail.created_on} label={"Vytvořil/a"}/><br/>
              <CreatorEditorText person={data.detail.edited_by} timestamp={data.detail.edited_on} label={"Upravil/a"}/>
            </Col>
          }
          <Col className="text-end">
            <EnrollDelete/>
            <EnrollSubmit/>
          </Col>
        </Row>
      </Form>
      {data?.event?.type === 0 && <ServicesSection />}
      <Row>
        <Col>
          <OfferOrderSection eventId={eventId} offers={data.offers} user={data.user} />
        </Col>
      </Row>
      <br/>
      <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Skutečně smazat přihlášku?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDelete(false)}>Zavřít</Button>
          <Button variant="primary" onClick={tryDeleteEnroll}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </PageContainer>
  );

  function StageSelection() {
    function StageCheckboxes() {
      const handleInputChange = (parameter, value) => {
        const change = Math.pow(2, parameter);
        (value) ? setStagesSelected(stagesSelected + change) : setStagesSelected(stagesSelected - change);
      };

      const stages = data?.event.stages_count;
      const rows = [];
      for(let idx = 1; idx <= stages; idx++) {
        let label = "Etapa " + idx;
        rows.push(<Col xs="auto" key={"st-"+idx}>
          <Form.Check inline key={"stage-"+idx} type="checkbox" label={label} defaultChecked={(stagesSelected & (1 << idx)) !== 0}
                      disabled={data?.event?.all_stages_only || data?.applied}
                      onChange={(e) => {handleInputChange(idx, e.target.checked)}}/>
        </Col>);
      }
      return(rows);
    }
    return (<Form.Group className="mb-3" controlId="form"><Row><StageCheckboxes/></Row></Form.Group>)
  }

  function ServicesSection() {
    const rows = [];
    for (const i in data.services) {
      const service = data.services[i];
      rows.push(<tr key={service.id}>
        <td className={(service.available <= 0 || !service?.can_order) ? "strike-link" : "hide-link"}>{service.title}</td>
        <td>{service.unit} Kč</td>
        <td><i>{parseDateTime(service.term)}</i></td>
      </tr>)
    }

    if (rows.length === 0)
      return;

    return (
      <>
        <hr/>
        <h4 className="pointing" onClick={() => setShowServices(!showServices)}><FontAwesomeIcon icon={showServices ? faAngleUp : faAngleDown}/> Doplňkové služby</h4>
        <p className="mb-2"><i>Služby nabízené pořadatelem akce</i></p>
        {showServices && <>
          <Table striped bordered hover={labels.CondElemTableHover} responsive>
            <thead>
            <tr>
              <th>Popis</th>
              <th>Cena</th>
              <th>Termín</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
          <Row>
            <Col className="text-end">
              <Link to={`/akce/${eventId}/sluzby`}><Button className="mb-2" variant="outline-primary"><FontAwesomeIcon icon={faHotel}/> Objednávky služeb</Button></Link>
            </Col>
          </Row>
        </>}
      </>
    );
  }

  function EnrollSubmit(){
    if(data?.applied){
      return(<Button style={{"marginLeft": "10px"}} variant="primary" type="submit">{labels.ButtonEntryUpdate}</Button>);
    }
    if (data.event.term === 0)
      return(<Button variant="info" disabled={data.user.classes.length === 0 || (transport > 1 && carCapacity == null)} type="submit" style={{fontSize: "1.1em"}}>Předběžně přihlásit</Button>);
    return(<Button variant="primary" disabled={extendedLimitReached || data.user.classes.length === 0 || (transport > 1 && carCapacity == null)} type="submit" style={{fontSize: "1.1em"}}><b>Přihlásit</b></Button>);
  }
  function EnrollDelete(){
    if(data?.applied && data?.detail?.delete_person){
      return(<Button variant="secondary" onClick={() => setConfirmDelete(true)}>Smazat přihlášku</Button>);
    }
  }
  function tryDeleteEnroll(){
    deleteEnroll(eventId, userId)
      .then((response)=>{
        if (response.ok) {
          setSuccess("Přihláška smazána");
          loadEnrollInfo();
        } else {
          setError(handleErrorSubmit(response, "Nepodařilo se smazat"));
        }
        setConfirmDelete(false);
      })
  }
  function getBackground(){
    if(data?.applied){
      if (data.detail.term === 0)
        return "rgba(112,230,253,0.2)";
      if (data.detail?.reserve_idx > 0)
        return "rgba(200,190,20,0.2)";
      return "rgba(2,200,30,0.2)";
    }
    return "rgba(0,0,0,0)";
  }
};

export default Enroll;