import React, {useEffect, useState} from "react";
import {deleteRequisitionEvent, getRequisitionEvents, postRequisitionEvent, putRequisitionEvent} from "../../dao";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import {handleErrorLoading, handleErrorSubmit, parseDate, parseDateBreakYear, setBrowserTabText, YearOptions} from "../../helpers/Functions";
import {Link} from "react-router-dom";
import Loader from "../../components/overlays/Loader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import NoYesIndicator from "../../components/visual/NoYesIndicator";
import PageContainer from "../../layout/PageContainer";
import LoadingCard from "../../components/overlays/LoadingCard";
import Form from "react-bootstrap/Form";
import {ReqEventTypeOptions, RequisitionEventsModes} from "../../helpers/Constants";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormSelectArray from "../../components/form/FormSelectArray";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import Card from "react-bootstrap/Card";
import {labels} from "../../themeLabels";


const RequisitionEvents = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [eventToChange, setEventToChange] = useState(null);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [addEvent, setAddEvent] = useState(false);
  const [year, setYear] = useState((new Date()).getFullYear());
  const [mode, setMode] = useState("K");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("10");

  useEffect(() => {
    loadData(year, mode);
  }, [year, mode]);

  function loadData(year, mode) {
    setLoading(true);
    getRequisitionEvents(year, mode)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  function changeBilling(id) {
    setProcessing(true);
    putRequisitionEvent(id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Nastavení vyúčtování změněno");
          loadData(year, mode);
        } else {
          setError(handleErrorSubmit(response, "Nastavení nešlo změnit"));
        }
        setEventToChange(null);
        setProcessing(false);
      })
  }

  function deleteEvent(id) {
    deleteRequisitionEvent(id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Smazáno");
          loadData(year, mode);
        } else {
          setError(handleErrorSubmit(response, "Akci nešlo smazat"));
        }
        setEventToDelete(null);
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    postRequisitionEvent(title, date, type)
      .then((response) => {
        if (response.ok) {
          setTitle("");
          setDate("");
          setType("10");
          setSuccess("Vytvořeno");
          loadData(year, mode);
        } else {
          setError(handleErrorSubmit(response, "Nepodařilo se vytvořit"));
        }
        setAddEvent(false);
      })
  };

  if (loading)
    return <Loader />;
  if (processing)
    return <LoadingCard />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Klubové pohledávky');
  return (
    <PageContainer>
      <h1>Klubové pohledávky</h1>
      <Row>
        <Col>
          <p><i>K akci lze přidat pohledávky kliknutím na název, nebo lze kliknutím na ikonu změnit, zda je akce vyúčtována uživatelům.</i></p>
        </Col>
      </Row>
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-end mt-1">
            <Col>
              <Row>
                <Col xs={12} sm={6}>
                  <FormSelectArray label={"Rok"} inline={true} size={"sm"} value={year} setValue={setYear} options={YearOptions()} controlId={"year"} />
                </Col>
                <Col xs={12} sm={6}>
                  <FormSelectDict label={"Typ"} inline={true} size={"sm"} value={mode} setValue={setMode} options={RequisitionEventsModes} controlId={"mode"} />
                </Col>
              </Row>
            </Col>
            <Col xs="auto" className="mb-3">
              <Button onClick={() => setAddEvent(true)}>+ Přidat</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <RequisitionEventsTable/>
      <Modal show={eventToChange != null} onHide={()=>setEventToChange(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce /> &nbsp;
          <Modal.Title>{eventToChange?.billed ? "Odúčtovat akci?" : "Vyúčtovat akci?"}</Modal.Title>
          <Modal.Body>{eventToChange != null && <>{parseDate(eventToChange.date)}<br/>{eventToChange.title}</>}</Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setEventToChange(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={()=>changeBilling(eventToChange.id)}>
            Změnit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={eventToDelete != null} onHide={()=>setEventToDelete(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce /> &nbsp;
          <Modal.Title>Skutečně smazat akci?</Modal.Title>
          <Modal.Body>{eventToDelete != null && <>{parseDate(eventToDelete.date)}<br/>{eventToDelete.title}</>}</Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setEventToDelete(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={()=>deleteEvent(eventToDelete.id)}>
            Smazat
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={addEvent} onHide={()=>setAddEvent(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Přidat akci pro vyúčtování</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><i>Akci vytvářej pouze pokud neexistuje, jinak nahraj pohledávky k založené akci.</i></p>
          <Form onSubmit={handleSubmit}>
            <FormField label={"Název"} value={title} setValue={setTitle} valueLimit={128} controlId={"title"} />
            <FormField label={"Datum"} type="date" value={date} setValue={setDate} controlId={"date"} />
            <FormSelectDict label={"Typ"} value={type} setValue={setType} options={ReqEventTypeOptions} controlId={"type"} />
            <Row>
              <Col className="col-md-12 text-end">
                <Button variant="primary" type="submit">Vytvořit</Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  );

  function RequisitionEventsTable() {
    const TypeShortcut = ({type}) => {
      switch (type) {
        case 1:
          return "TR";
        case 2:
        case 10:
          return "SO";
        case 11:
          return "ČP";
        case 12:
          return "JI";
        default:
          return "";
      }
    }

    if (data.events.length < 1){
      return(<p><i>Žádné pohledávky v historii</i></p>)
    }
    const rows = [];
    data.events.forEach((event)=>{
      rows.push(<tr key={event.id}>
        <td>{parseDateBreakYear(event.date)}</td>
        <td><Link to={"/akce/"+event.id+"/pohledavky"} >{event.title}</Link></td>
        <td><TypeShortcut type={event.type} /></td>
        <td align="center" title={event.billed ? "Akce účtována" : "Akce neúčtována"} onClick={()=>setEventToChange(event)}><NoYesIndicator condition={event.billed} large={true} /></td>
        <td align="center"><Link to={"/akce/"+event.id+"/pohledavky"} >{event.reqs}</Link></td>
        <td align="center" title="Akci lze smazat pouze pokud nemá žádné přihlášky ani pohledávky">{event.reqs === 0 && event.removable && <FontAwesomeIcon icon={faTrash} className="pointing link-like" onClick={()=>setEventToDelete(event)}/>}</td>
      </tr>);
    });

    return (
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th>Datum</th>
          <th>Název</th>
          <th>Typ</th>
          <th title="Zda je akce účtována lidem">Účt.</th>
          <th title="Počet přidružených pohledávek">Pohl.</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }
}

export default RequisitionEvents;