import React, {useEffect, useState} from "react";
import {deleteRequisition, getRequisitions, postRequisition, postRequisitionCsv, putRequisition, sendEmails} from "../../dao";
import Button from "react-bootstrap/Button";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {Link, useParams} from "react-router-dom";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {isMobileDevice} from "../../helpers/Device";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faPaperPlane, faPencil,
  faQuestionCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Loader from "../../components/overlays/Loader";
import Alert from "react-bootstrap/Alert";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText, verifyFileIsCsv} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Form from "react-bootstrap/Form";
import NoYesIndicator from "../../components/visual/NoYesIndicator";
import PageContainer from "../../layout/PageContainer";
import LoadingCard from "../../components/overlays/LoadingCard";
import PageHeading from "../../layout/PageHeading";
import {BillingModeOptions, HomeClub} from "../../helpers/Constants";
import InputGroup from "react-bootstrap/InputGroup";
import FormField from "../../components/form/FormField";
import SearchBar from "../../components/form/SearchBar";
import FormCheckbox from "../../components/form/FormCheckbox";
import {labels} from "../../themeLabels";


const Requisitions = () => {
  const { eventId } = useParams();
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [confirmSending, setConfirmSending] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [result, setResult] = useState("");
  const [response, setResponse] = useState({});
  const [addRequisition, setAddRequisition] = useState(false);
  const [user, setUser] = useState(null);
  const [changingAssigned, setChangingAssigned] = useState(false);
  const [reqToEdit, setReqToEdit] = useState(null);
  const [fee, setFee] = useState(0);
  const [feeFine, setFeeFine] = useState(0);
  const [started, setStarted] = useState(true);
  const [accommodationFee, setAccommodationFee] = useState(0);
  const [transportFee, setTransportFee] = useState(0);
  const [reqToRemove, setReqToRemove] = useState(null);

  useEffect(() => {
    loadData();
  }, [eventId]);

  function loadData(){
    setLoading(true);
    getRequisitions(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response))
          return []
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      });
  }

  const changeHandler = (event) => {
    if(verifyFileIsCsv(event.target.files[0])){
      setSelectedFile(event.target.files[0]);
      setIsSelected(true);
    }else{
      setError("Špatný typ souboru");
    }
  };

  const handleSubmission = () => {
    const formData = new FormData();
    formData.append('File', selectedFile);
    setProcessing(true);
    postRequisitionCsv(eventId, formData)
      .then((response) => {
        if(response.ok){
          response.json().then(
            (result) => {
              setSuccess("Úspěšně nahráno");
              setResponse(result);
              setResult("Přidáno: " + result.added + ", ignorováno: " + result.ignored + ", neúspěšné: " + result.failed);
            });
          loadData();
        }else{
          setError(handleErrorSubmit(response, "Nezdařilo se nahrát"));
        }
        setProcessing(false);
      });
  };

  const handleSendEmails = () => {
    sendEmails('event=' + eventId)
      .then((response) => {
        if (response.ok)
          setSuccess("Požadavek přijat");
        else
          setError(handleErrorSubmit(response, "Operace se nezdařila"));
        setConfirmSending(false);
      })
  }

  function addReq() {
    postRequisition(eventId, user.id, fee)
      .then((res) => {
        if (res.ok) {
          setSuccess("Přidáno");
          loadData();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se přidat"));
        }
      })
    setAddRequisition(false);
  }

  const editEntryReq = (id, edited=1) => {
    setProcessing(true);
    const formData = {
      "edited": edited,
      "fee": fee,
      "fee_fine": feeFine,
      "started": started,
      "transport_fee": transportFee,
      "accommodation_fee": accommodationFee
    };
    putRequisition("entry=" + id, formData)
      .then((res) => {
        if (res.ok) {
          setSuccess("Upraveno");
          res.json().then((response) => setData(response));
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se upravit"));
        }
        setProcessing(false);
      })
    setReqToEdit(null);
  }

  const editReq = (id) => {
    setProcessing(true);
    const formData = {
      "fee": fee
    };
    putRequisition("req=" + id, formData)
      .then((res) => {
        if (res.ok) {
          setSuccess("Upraveno");
          res.json().then((response) => setData(response));
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se upravit"));
        }
        setProcessing(false);
      })
    setReqToEdit(null);
  }

  function removeReq(id){
    setProcessing(true);
    deleteRequisition(id)
      .then((res) => {
        if (res.ok) {
          setSuccess("Smazáno");
          loadData();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se smazat"));
        }
        setProcessing(false);
      })
    setReqToRemove(null);
  }

  const alertColor = (response?.added === 0 || response?.ignored > 0 || response?.failed > 0) ? "warning" : "success";

  if (loading)
    return <Loader/>;
  if (processing)
    return <LoadingCard />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  setBrowserTabText('Pohledávky | ' + data?.event?.title);
  return (
    <PageContainer>
      <PageHeading heading={"Pohledávky: " + data?.event?.title} link={"/akce/"+eventId+"/admin"} label={"Admin akce"} />
      {result !== "" && <Alert variant={alertColor}>{result}</Alert>}
      <PostCsvCard />
      <SummaryCard />
      <RequisitionTable />
      <Modal show={confirmSending} onHide={() => setConfirmSending(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Odeslat členům emaily s výzvou k zaplacení?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmSending(false)}>Zavřít</Button>
          <Button variant="primary" onClick={handleSendEmails}>Potvrdit</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={reqToRemove != null} onHide={() => setReqToRemove(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Smazat pohledávku?</Modal.Title>
          <Modal.Body>{reqToRemove != null ? <>{reqToRemove.full_name}<br/>{reqToRemove.reg_number}<br/>{reqToRemove.fee} Kč</> : <></>}</Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setReqToRemove(null)}>Zavřít</Button>
          <Button variant="primary" onClick={() => removeReq(reqToRemove.record_id)}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={addRequisition} onHide={() => setAddRequisition(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Přidat{!isMobileDevice() && " pohledávku"}:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="form">
              <Form.Label>Uživatel:</Form.Label>
              <InputGroup>
                <Form.Control type="text" style={{ maxWidth: '80%' }} value={user == null ? "NEPŘIŘAZEN!" : user?.reg_number + " – " + user?.full_name}/>
                <Button style={{ width: '20%' }} onClick={() => setChangingAssigned(true)}>Vybrat</Button>
              </InputGroup>
            </Form.Group>
          </Form>
          <FormField label={"Částka"} type={"number"} value={fee} setValue={setFee} valueLimit={5} controlId={"fee"} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAddRequisition(false)}>
            Zavřít
          </Button>
          <Button variant="primary" disabled={user == null} onClick={addReq}>
            Přidat
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={reqToEdit != null && data?.event?.type !== 0} onHide={() => setReqToEdit(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Editovat{!isMobileDevice() && " pohledávku"}:</Modal.Title>
          <Modal.Body>{reqToEdit?.full_name}<br/><i>{reqToEdit?.reg_number} – {reqToEdit?.membership}</i></Modal.Body>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <FormField label={"Účtováno"} type={"number"} value={fee} setValue={setFee} valueLimit={5} controlId={"fee"} />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setReqToEdit(null)}>Zavřít</Button>
          <Button variant="primary" onClick={() => editReq(reqToEdit.record_id)}>Změnit</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={reqToEdit != null && data?.event?.type === 0} onHide={() => setReqToEdit(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Editovat{!isMobileDevice() && " pohledávku"}:</Modal.Title>
          <Modal.Body>{reqToEdit?.full_name}<br/><i>{reqToEdit?.reg_number} – {reqToEdit?.membership}</i></Modal.Body>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Table responsive borderless className="mb-0">
              <thead>
              <tr>
                <th></th>
                <th title="Startovné z pohledu účtování akce (kolik bylo placeno pořadatelům)">Startovné</th>
                <th title="Doplatek za T2/3 nebo jiný">Doplatek</th>
                <th title="Uživatel startoval ano/ne (zda se počítá jako DNS)">Startoval/a</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td title="Vypočteno z Orisu">Oris:</td>
                <td>{reqToEdit?.requisition?.fee}</td>
                <td>{reqToEdit?.requisition?.fee_fine}</td>
                <td><NoYesIndicator condition={reqToEdit?.requisition?.started}/></td>
              </tr>
              {reqToEdit?.requisition?.is_edited && <tr>
                <td title="Manuálně nastaveno">Man:</td>
                <td>{reqToEdit?.requisition?.manual_fee}</td>
                <td>{reqToEdit?.requisition?.manual_fee_fine}</td>
                <td><NoYesIndicator condition={reqToEdit?.requisition?.manual_started}/></td>
              </tr>}
              <tr>
                <th colSpan={4}>Nastavit manuálně</th>
              </tr>
              <tr>
                <td></td>
                <td>
                  <FormField label={""} type={"number"} value={fee} setValue={setFee} valueLimit={5} controlId={"fee"}/>
                </td>
                <td>
                  <FormField label={""} type={"number"} value={feeFine} setValue={setFeeFine} valueLimit={5} controlId={"feeFine"}/>
                </td>
                <td>
                  <FormCheckbox label={""} value={started} setValue={setStarted} controlId={"feeStarted"}/>
                </td>
              </tr>
              </tbody>
            </Table>
            <hr/>
            <Table responsive borderless className="mb-0">
              <thead>
              <tr>
                <th>Ubytování</th>
                <th>Dopravné</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <FormField label={""} type={"number"} value={accommodationFee} setValue={setAccommodationFee} disabled={reqToEdit?.accommodation === 0 || reqToEdit?.membership !== "P"} valueLimit={5} controlId={"accommodationFee"}/>
                </td>
                <td>
                  <FormField label={""} type={"number"} value={transportFee} setValue={setTransportFee} disabled={reqToEdit?.transport === 0 || reqToEdit?.membership !== "P"} valueLimit={5} controlId={"transportFee"}/>
                </td>
              </tr>
              </tbody>
            </Table>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setReqToEdit(null)}>Zavřít</Button>
          <Button variant="outline-primary" onClick={() => {editEntryReq(reqToEdit.record_id, 0)}}>Reset</Button>
          <Button variant="primary" onClick={() => editEntryReq(reqToEdit.record_id)}>Změnit</Button>
        </Modal.Footer>
      </Modal>
      <ChangeAssigned
        show={changingAssigned}
        onHide={() => {setChangingAssigned(false);}}
        people={data.members}
      />
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </PageContainer>
  )

  function PostCsvCard() {
    if (data.event.type === 0 || !data.event.editable)
      return <></>;
    return (
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Nahrát pohledávky</Card.Title>
          <Row className="align-items-center">
            <Col style={{minWidth: '40%'}}>
              <p><b>Pořadí sloupců</b> je následující:</p>
              <ol type="A">
                <li>reg. číslo (ve formátu {HomeClub}0123)</li>
                <li>částka (číslo bez měny)</li>
              </ol>
              <p>
                Tabulka <b>nesmí obsahovat hlavičku</b><br/>
                Validita záznamů <b>není kontrolována</b>
              </p>
            </Col>
            <Col style={{minWidth: '50%'}}>
              <Form.Group controlId="formFile" className="mb-3">
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Button variant="outline-primary" onClick={() => document.getElementById('csvFileSelect').click()}>Vyber CSV</Button>
                    <input className="form-control d-none" id='csvFileSelect' type="file" onChange={changeHandler}></input>
                  </Col>
                  {isSelected && <Col><b>{selectedFile.name}</b>, velikost: {selectedFile.size} B</Col>}
                </Row>
              </Form.Group>
              <div>
                <Button className="btn btn-primary" disabled={!isSelected} onClick={handleSubmission}>Nahrát</Button>
              </div>
            </Col>
            <Col style={{width: '90px'}} className="text-end align-self-end">
              <Button onClick={() => {
                setUser(null);
                setFee(0);
                setAddRequisition(true);}}>+ Přidat</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function SummaryCard() {
    return (
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col><Card.Title>Souhrn</Card.Title></Col>
            <Col className="text-end"><i>Režim vyúčtování: {BillingModeOptions[data.event.billing_mode]}</i></Col>
          </Row>
          <Row>
            <Col>
              <p className="mb-0">Celkem: {isMobileDevice() && <br/>}<b>{data.summary.sum} Kč</b></p>
              {data.event.type !== 0 ? <p className="mb-0"><Link to={`/ucetnictvi/pohledavky`} className="hide-link">Účtováno: <NoYesIndicator condition={data.event.billed} allBlack={true}/></Link></p> :
                <>
                  <br/>
                  <hr className="mt-1 mb-1" style={{maxWidth: "50%"}}/>
                  <p className="mb-0">Klubové dop.+ub.: {isMobileDevice() && <br/>}{data.summary?.flat_rates} Kč</p>
                </>}
            </Col>
            {data.event.type === 0 ? <>
              <Col>
                <Row>Startovné: {isMobileDevice() && <br/>}{data.summary?.fee_sum} Kč</Row>
                <hr className="mt-1 mb-1" style={{maxWidth: "50%"}}/>
                <Row>{data.summary?.services_sum > 0 ? <a className="ps-0" href={`/akce/${eventId}/sluzby`}>Služby: {isMobileDevice() && <br/>}{data.summary?.services_sum} Kč</a> : <>Služby: {isMobileDevice() && <br/>}{data.summary?.services_sum} Kč</>}</Row>
                <Row title="část ze startovného hrazená zpětně členy">Doplatky: {isMobileDevice() && <br/>}{data.summary?.fines} Kč</Row>
              </Col>
              <Col>
                <Row>Přispívající: {isMobileDevice() && <br/>}{data.summary?.fees_P} Kč</Row>
                <Row>Řadoví sami: {isMobileDevice() && <br/>}{data.summary?.fees_self} Kč</Row>
                <Row>Řadoví dotace: {isMobileDevice() && <br/>}{data.summary?.fees_grants} Kč</Row>
                <Row>R a P jinak: {isMobileDevice() && <br/>}{data.summary?.fees_not_grants} Kč</Row>
                {data.event?.is_relay && <>
                  <Row>Hostující DH18: {isMobileDevice() && <br/>}{data.summary?.fee_hosts} Kč</Row>
                  <Row>Zbylé úseky: {isMobileDevice() && <br/>}{data.summary?.fee_residue} Kč</Row>
                </>}
              </Col>
            </> : <Col className="text-end">
              <Button onClick={() => setConfirmSending(true)}><FontAwesomeIcon icon={faPaperPlane} /> Odeslat výzvy k zaplacení</Button>
            </Col>}
          </Row>
          {(data.event.currency !== "CZK" && data.event.currency !== "") && <Row className="mt-1"><Col>Měna: {data.event.currency} | 1 {data.event.currency} = {data.event.exchange_rate} CZK</Col></Row>}
        </Card.Body>
      </Card>
    )
  }

  function RequisitionTable(){
    const [filteredPeople, setFilteredPeople] = useState(data.records);
    if(data.records.length < 1){
      return(<p><i>K této akci nejsou žádné pohledávky</i></p>)
    }
    const rows = [];
    filteredPeople.forEach((record)=>{
      rows.push(<tr key={record.id}>
        {isMobileDevice() ?
          (<td>{record.full_name}<br/><i><Link to={`/ucet/${record.id}/vyuctovani/detail`} className="no-decoration">{record.reg_number} – {record.membership}</Link></i></td>) :
          (<>
            <td><Link to={`/ucet/${record.id}/vyuctovani/detail`} className="no-decoration">{record.reg_number}</Link></td>
            <td>{record.full_name}</td>
            <td>{record.membership}</td>
          </>)
        }
        {data.event.type === 0 ? <>
          <td>{record.fee}</td>
          <td className="td-sep"/>
          <td>{record.fee_billed}</td>
          <td className="td-sep"/>
          <td>{record.fee_fine}</td>
          <td align="center"><NoYesIndicator condition={record.started}/></td>
        </> : <td>{record.fee}</td>}
        {data.event.editable ?
          <td onClick={() => {
            setReqToEdit(record);
            if (data.event.type !== 0)
              setFee(record.fee);
            else {
              const requisition = record.requisition;
              if (requisition.is_edited) {
                setFee(requisition.manual_fee);
                setFeeFine(requisition.manual_fee_fine);
                setStarted(requisition.manual_started);
              } else {
                setFee(requisition.fee);
                setFeeFine(requisition.fee_fine);
                setStarted(requisition.started);
              }
              setTransportFee(requisition.transport_fee);
              setAccommodationFee(requisition.accommodation_fee);
            }
          }} className="td-w-icon pointing">
            <FontAwesomeIcon icon={faPencil} className={data.event.type === 0 && record?.requisition?.is_edited ? "link-like-marked" :"link-like"}/></td> : <td></td>}
        {data.event.type !== 0 && <td onClick={() => setReqToRemove(record)} className="td-w-icon pointing">
          <FontAwesomeIcon icon={faTrash} className="link-like"/></td>}
      </tr>);
    });

    return (
      <>
        <SearchBar data={data.records} setFilteredPeople={setFilteredPeople} />
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            {isMobileDevice() ? <th>Osoba</th> : (<>
              <th>Reg.</th>
              <th>Jméno</th>
              <th>Čl.</th>
            </>)}
            {data.event.type === 0 ? <>
              <th>Start.</th>
              <th className="td-sep"/>
              <th title="Částka účtovaná přímo">Účt.</th>
              <th className="td-sep"/>
              <th title="Doplatek (T2/3 nebo ručně nastavený)">Dopl.</th>
              <th title="Startoval/a ano/ne">St.</th>
            </> : <>
              <th>Částka</th>
              <th></th>
            </>}
            <th></th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      </>
    );
  }

  function ChangeAssigned(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Vybrat uživatele k pohledávce
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PeopleTable people={props.people}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setChangingAssigned(false)}>Zavřít</Button>
        </Modal.Footer>
      </Modal>
    );

    function PeopleTable(props){
      const [filteredPeople, setFilteredPeople] = useState(props.people);

      if(props.people?.length > 0){
        const rows = [];
        for(var i in filteredPeople){
          const person = filteredPeople[i];
          rows.push(
            <tr key={person.id}>
              <td>{person.reg_number}</td>
              <td>{person.full_name}</td>
              <td>
                <Button onClick={()=> {
                  setUser(person);
                  setChangingAssigned(false);
                }}>Vybrat</Button>
              </td>
            </tr>
          );
        }
        return(
          <>
            <SearchBar data={props.people} setFilteredPeople={setFilteredPeople} />
            <Table>
              <tbody>
              {rows}
              </tbody>
            </Table>
          </>
        )
      }
      return(<p>Nenalezeny žádné možnosti.</p>);
    }
  }
};

export default Requisitions;