import React, {useEffect, useState} from "react";
import {deleteOffer, getOffersEdit, postPutOffer} from "../../dao";
import {handleErrorLoading, handleErrorSubmit, parseDateTime} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../overlays/ErrorAlertFullscreen";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faQuestionCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import ErrorAlert from "../overlays/ErrorAlert";
import SuccessAlert from "../overlays/SuccessAlert";
import Table from "react-bootstrap/Table";
import FormField from "../form/FormField";
import WarningCard from "../overlays/WarningCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingIcon from "../overlays/LoadingIcon";
import {labels} from "../../themeLabels";


const EventClubOffersEdit = ({eventId}) => {
  const [data, setData] = useState({offers: [], count: -1});
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [addEditOffer, setAddEditOffer] = useState(false);
  const [removeOffer, setRemoveOffer] = useState(false);
  const [offerId, setOfferId] = useState(null);
  const [offerTitle, setOfferTitle] = useState("");
  const [offerFee, setOfferFee] = useState("");
  const [offerAmount, setOfferAmount] = useState("");
  const [offerTerm, setOfferTerm] = useState("");

  useEffect(() => {
    getOffersEdit(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response))
          return {offers: [], count: 0}
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
      });
  }, [eventId]);

  function handleAddEditOffer() {
    const formData = {
      "title": offerTitle,
      "fee": Number(offerFee),
      "amount": Number(offerAmount),
      "term": offerTerm
    };
    const targetId = (offerId ? offerId : eventId);
    postPutOffer(targetId, formData, (offerId ? "PUT" : "POST"))
      .then((response) => {
        if (response.ok) {
          setSuccess("Uloženo");
          response.json().then((response) => setData(response));
          setOfferId(null);
          setAddEditOffer(false);
        } else {
          setError(handleErrorSubmit(response, "Nezdařilo se provést"));
        }
      });
  }

  function handleRemove() {
    deleteOffer(offerId)
      .then((response) => {
        if (response.ok) {
          setSuccess("Smazáno");
          response.json().then((response) => setData(response));
          setOfferId(null);
          setRemoveOffer(false);
        } else {
          setError(handleErrorSubmit(response, "Nezdařilo se smazat"));
        }
      });
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  return (
    <div>
      <Row className="align-items-center mb-2">
        <Col>
          <h3>Klubové nabídky</h3>
        </Col>
        <Col xs="auto">
          <Button variant="secondary" onClick={() => {
            setOfferId(null);
            setOfferTitle("");
            setOfferFee("");
            setOfferAmount("");
            setOfferTerm("");
            setAddEditOffer(true);
          }}>+ Přidat</Button>
        </Col>
      </Row>
      <OfferTable/>
      <Modal show={addEditOffer} onHide={() => {
        setOfferId(null);
        setAddEditOffer(false);
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{offerId == null ? "Přidat" : "Editovat"} nabídku</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormField label={"Název"} value={offerTitle} setValue={setOfferTitle} inline={true} digits={"none"} isInvalid={!offerTitle} controlId={"offerTitle"}/>
            <FormField label={"Počet"} value={offerAmount} setValue={setOfferAmount} inline={true} type={"number"} isInvalid={Number(offerAmount) <= 0} controlId={"offerAmount"}/>
            <FormField label={"Cena"} value={offerFee} setValue={setOfferFee} inline={true} type={"number"} controlId={"offerFee"}/>
            <FormField label={"Termín"} value={offerTerm} setValue={setOfferTerm} inline={true} type={"datetime-local"} controlId={"offerTerm"}/>
          </Form>
          <p><i>Pozn. cenu objednávky lze zadat, v objednávce služby se uživatelům zobrazí, nicméně v aktuální verzi se ceny nesčítají do vyúčtování lidí ani do výpisu v účetnictví (nebylo vyřešeno jak a kam)!</i></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setOfferId(null);
            setAddEditOffer(false);
          }}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleAddEditOffer} disabled={!offerTitle || Number(offerAmount) <= 0}>
            {offerId == null ? "Přidat" : "Změnit"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={removeOffer} onHide={() => setRemoveOffer(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Smazat nabídku "{offerTitle}"?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Všechny objednávky této nabídky budou také smazány</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setRemoveOffer(false)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleRemove}>
            Smazat
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </div>
  );

  function OfferTable() {
    if (data.count === -1)
      return <LoadingIcon />;

    const rows = [];
    for (const i in data.offers) {
      const offer = data.offers[i];
      rows.push(<tr key={offer.id}>
        <td>{offer.title}</td>
        <td>{offer.ordered} / {offer.amount} ({offer.available})</td>
        <td>{offer.unit}</td>
        <td>{parseDateTime(offer.term)}</td>
        <td onClick={() => {
          setOfferId(offer.id);
          setOfferTitle(offer.title);
          setOfferFee(offer.unit);
          setOfferAmount(offer.amount);
          setOfferTerm(offer.term ? offer.term : "");
          setAddEditOffer(true);
        }} className="text-center pointing"><FontAwesomeIcon icon={faPencil} className="link-like"/></td>
        <td onClick={() => {
          setOfferId(offer.id);
          setOfferTitle(offer.title);
          setRemoveOffer(true);
        }} className="text-center pointing"><FontAwesomeIcon icon={faTrash} className="link-like"/></td>
      </tr>);
    }

    if (rows.length === 0)
      return <WarningCard text={"Žádné nabídky"} marginTop={"2%"} />

    return (
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th>Název</th>
          <th>Mn.</th>
          <th>Kč</th>
          <th>Termín</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }
}

export default EventClubOffersEdit;
