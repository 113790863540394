import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/overlays/Loader";
import {editMyTransport, getTransportInfo} from "../../dao";
import { useState } from "react";
import {faCar, faCarSide, faUsers} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "react-bootstrap/esm/Button";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Alert from "react-bootstrap/Alert";
import PageHeading from "../../layout/PageHeading";
import EventDate from "../../components/parts/EventDate";
import VehicleList from "../../components/parts/VehicleList";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {labels} from "../../themeLabels";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";

export default function Transport(){
  const { eventId } = useParams();
  const [data, setData]= useState({});
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");

  useEffect(() => {
    setLoading(true);
    loadData();
  }, [eventId]);

  function loadData() {
    getTransportInfo(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {};
        }
        return response.json()})
      .then((response) => {
        setData(response);
        setLoading(false);
        setProcessing(false);
      })
  }

  function editCrew(vehicleId, personId) {
    setProcessing(true);
    editMyTransport(vehicleId, 'crew', personId)
      .then((res) => {
        if (res.ok) {
          setSuccess("Upraveno");
          loadData();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se upravit"));
        }
      })
  }

  if (loading)
    return <Loader loading={loading} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Doprava | ' + data?.event?.title);
  return (
    <PageContainer>
      <PageHeading heading={"Klubová doprava: " + data?.event?.title} id={eventId}/>
      <p><EventDate event={data?.event} /></p>
      {data?.logged?.editor && <>
        <Row className="align-items-center">
          <Col xs="auto" className="mb-3">
            <Link to={`/akce/${eventId}/doprava/rozdeleni`}>
              <Button className="tapis-95"><FontAwesomeIcon icon={faCar} /><FontAwesomeIcon icon={faUsers} /> Rozdělení</Button>
            </Link>
          </Col>
          {(!data.transport_handled && !labels.CondMyTransportSelf) &&
            <Col>
              <Alert variant="warning">Rozdělení dopravy není zveřejněno uživatelům!</Alert>
            </Col>
          }
          {(data.transport_handled && labels.CondMyTransportSelf) &&
            <Col>
              <Alert variant="warning">Rozdělení dopravy uzamčeno pro úpravy</Alert>
            </Col>
          }
          <Col xs="auto" className="mb-3">
            <Link to={`/akce/${eventId}/admin`}>Administrace akce</Link>
          </Col>
        </Row>
      </>
      }
      {data?.transport_info &&
        <Row>
          <Col>
            <Alert variant="info">Info o dopravě: {data.transport_info}</Alert>
          </Col>
        </Row>
      }
      <Row className="align-items-center mb-2">
        <Col>
          <h3><FontAwesomeIcon icon={faCarSide}/> Vozidla</h3>
        </Col>
        {labels.CondMyTransportSelf && <Col className="text-end">
          <p className="mb-1">Hledá místo: <b>{data?.transport_requests}</b></p>
          <p className="mb-1">Volná místa: <b>{data?.transport_places}</b></p>
        </Col>}
      </Row>
      <Row className="ps-1 pe-1">
        <VehicleList eventId={eventId} vehicles={data?.vehicles} user={data?.logged} editCrew={editCrew} processing={processing}
                     assignable={data?.assignable} removable={data?.removable}/>
      </Row>
      <LoadingOverlay loading={processing}/>
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  )
}