import React, {useEffect, useState} from "react";
import {deleteEmailFromQueue, getServerEmailQueue} from "../../dao";
import {handleErrorLoading, handleErrorSubmit, parseDateTimeSec, setBrowserTabText} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NoYesIndicator from "../../components/visual/NoYesIndicator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBug,
  faBugSlash,
  faCircleExclamation,
  faCoins,
  faHand,
  faPause, faPlay,
  faQuestionCircle,
  faTrash,
  faUnlock,
  faUserSecret
} from "@fortawesome/free-solid-svg-icons";
import FormCheckbox from "../../components/form/FormCheckbox";
import PageSwitch from "../../components/visual/PageSwitch";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {ThemeCount} from "../../themeLabels";


const ServerEmailQueue = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState(null);
  const [page, setPage] = useState(1);

  const rowsPerPage = 30;

  useEffect(() => {
    setLoading(true);
    getServerEmailQueue(showAll)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [showAll]);

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  const EmailType = ({type}) => {
    switch (type) {
      case 1:
        return <FontAwesomeIcon icon={faHand} color="green"/>
      case 2:
      case 3:
        return <FontAwesomeIcon icon={faCoins} color="red"/>
      case 4:
        return <FontAwesomeIcon icon={faUnlock} color="blue"/>
      case 5:
        return <FontAwesomeIcon icon={faBug}/>
      case 6:
        return <FontAwesomeIcon icon={faUserSecret} color="orange"/>
      case 7:
        return <FontAwesomeIcon icon={faCircleExclamation} color="magenta"/>
      case 8:
        return <FontAwesomeIcon icon={faBugSlash} />
      default:
        return <></>;
    }
  }

  const handleEmailDelete = () => {
    deleteEmailFromQueue(emailToDelete.id)
      .then((response) => {
        if (response.ok)
          setSuccess("Požadavek přijat");
        else
          setError(handleErrorSubmit(response, "Operace se nezdařila"));
        setEmailToDelete(null);
      })
  }

  setBrowserTabText('Fronta emailů');
  return (
    <PageContainer>
      <h1>Fronta emailů – {showAll ? "historie" : "aktuální"}</h1>
      <Row>
        <Col xs>
          <p className="mb-0">čeká: <b>{data?.waiting}</b> {data?.sending ? <FontAwesomeIcon icon={faPlay} color="green"/> : <> - <FontAwesomeIcon icon={faPause} color="orange"/> (pozastaveno)</>}</p>
          <p>stav: {parseDateTimeSec(data?.timestamp)}</p>
        </Col>
        <Col xs="auto">
          <FormCheckbox label="Všechny záznamy" type="switch" value={showAll} setValue={setShowAll} controlId="showAll"/>
        </Col>
      </Row>
      <EmailQueueTable />
      <Modal show={emailToDelete != null} onHide={() => {setEmailToDelete(null)}}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Smazat email z fronty?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
            <tbody>
            <tr>
              <td>Adresát:</td>
              <td>{emailToDelete?.recipient?.full_name} <i>{emailToDelete?.recipient?.reg_number}</i></td>
            </tr>
            <tr>
              <td>Typ zprávy:&nbsp;&nbsp;</td>
              <td><EmailType type={emailToDelete?.email_type}/></td>
            </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setEmailToDelete(null);
          }}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleEmailDelete}>
            Smazat
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => {
        setSuccess("");
        window.location.reload();
      }}/>
    </PageContainer>
  )

  function EmailQueueTable() {
    const rows = [];

    const rowPage = data.queue.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    let idx = (page - 1) * rowsPerPage;  // initialize idx correctly for rows on previous pages
    for (const i in rowPage) {
      const element = rowPage[i];
      idx++;
      rows.push(
        <tr key={i}>
          <td>{element?.id}</td>
          <td>{element?.recipient?.full_name} <i>{element?.recipient?.reg_number}</i></td>
          <td className="align-center"><EmailType type={element?.email_type}/></td>
          <td>{parseDateTimeSec(element?.created)} / <i>{parseDateTimeSec(element?.processed)}</i></td>
          {showAll ? <>
            <td className="align-center"><NoYesIndicator condition={element?.processing} allBlack={true}/></td>
            <td className="align-center"><NoYesIndicator condition={element?.sent} allBlack={true}/></td>
          </> : <>
            <td>{data?.sending ? idx * ThemeCount : <><FontAwesomeIcon icon={faPause}/> ({idx * ThemeCount})</>}</td>
            <td className="align-center">{data?.sending ? <FontAwesomeIcon icon={faTrash} color="grey"/> :
              <FontAwesomeIcon icon={faTrash} onClick={() => setEmailToDelete(element)}/>}</td>
          </>}
        </tr>);

    }

    if (rows.length > 0) {
      return (
        <>
          <PageSwitch page={page} setPage={setPage} rowsPerPage={rowsPerPage} rowCount={data.queue.length} />
          <Table size="sm" striped bordered hover responsive>
            <thead>
            <tr>
              <th>#</th>
              <th>Příjemce</th>
              <th>T</th>
              <th title="created / processed">C / P</th>
              {showAll ? <>
                <th title="processing">P</th>
                <th title="done, email odeslán">D</th>
              </> : <>
                <th title="estimated processing time">ET [min]</th>
                <th></th>
              </>}
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
          <PageSwitch page={page} setPage={setPage} rowsPerPage={rowsPerPage} rowCount={data.queue.length} />
          <Row>
            <p className="mb-0"><b>Vysvětlivky:</b></p>
            <p className="mb-0"><EmailType type={1}/> – uvítání a přihlašovací údaje</p>
            <p className="mb-0"><EmailType type={2}/> – vyúčtování</p>
            <p className="mb-0"><EmailType type={4}/> – zapomenuté heslo</p>
            <p className="mb-0"><EmailType type={5}/> – nahlášení chyby</p>
            <p className="mb-0"><EmailType type={8}/> – odpověď na nahlášení</p>
            <p className="mb-0"><EmailType type={6}/> – jednorázová příhláška</p>
            <p className="mb-0"><EmailType type={7}/> – hlášení o chybě serveru</p>
          </Row>
        </>
      );
    }
    return (<p><i>Žádné emaily ve frontě</i></p>);
  }
}

export default ServerEmailQueue;