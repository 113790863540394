import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from 'react';
import { getEventRank } from "../../dao";
import { useParams } from "react-router-dom";
import Loader from "../../components/overlays/Loader";
import {durationTimeToFloat, handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import EventDate from "../../components/parts/EventDate";
import PageHeading from "../../layout/PageHeading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormField from "../../components/form/FormField";
import Button from "react-bootstrap/Button";
import {labels} from "../../themeLabels";

const EventRank = () => {
  const { eventId } = useParams();
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [classPointCalc, setClassPointCalc] = useState(null);
  const [timeMe, setTimeMe] = useState("");
  const [timeFirst, setTimeFirst] = useState("");
  const [timeSecond, setTimeSecond] = useState("");
  const [timeThird, setTimeThird] = useState("");
  const [myPlace, setMyPlace] = useState(1);
  const [classified, setClassified] = useState(4);
  const [rankPoints, setRankPoints] = useState(-1);

  useEffect(() => {
    setLoading(true);
    getEventRank(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [eventId]);

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Ranking | ' + data?.event.title);
  return (
    <PageContainer>
      <PageHeading heading={"Předzávodní rankingové statistiky"} id={eventId} />
      <h3>{data?.event.title}</h3>
      <p className="mb-2"><EventDate event={data?.event}/></p>
      <RankTable/>
      <p><i>Průměrné body jsou vypočteny ze startovek (pokud jsou k dispozici) nebo z aktuálně přihlášených závodníků, neberou v potaz výsledky a neúčast.</i>
      </p>
      <Modal show={classPointCalc != null} onHide={() => {
        setClassPointCalc(null);
        setTimeMe("");
        setTimeFirst("");
        setTimeSecond("");
        setTimeThird("");
        setMyPlace(1);
        setClassified(4);
        setRankPoints(-1);
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Kalkulačka bodů {classPointCalc?.name}:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><i>Výpočet přibližných získaných bodů do rankingu</i></p>
          <Form>
            <Row>
              <Col xs={8}>
                <FormField label={"Můj čas"} type={"duration"} value={timeMe} setValue={setTimeMe} controlId={"timeMe"} />
              </Col>
              <Col>
                <FormField label={"PB"} value={classPointCalc?.PB} disabled={true} digits={"none"} controlId={"PB"} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormField label={"Čas #1"} type={"duration"} value={timeFirst} setValue={setTimeFirst} controlId={"timeFirst"} />
              </Col>
              <Col>
                <FormField label={"Čas #2"} type={"duration"} value={timeSecond} setValue={setTimeSecond} controlId={"timeSecond"} />
              </Col>
              <Col>
                <FormField label={"Čas #3"} type={"duration"} value={timeThird} setValue={setTimeThird} controlId={"timeThird"} />
              </Col>
            </Row>
            {classPointCalc?.KS !== 0 && <Row>
              <Col>
                <FormField label={"Mé umístění"} type={"number"} value={myPlace} setValue={setMyPlace} controlId={"myPlace"} />
              </Col>
              <Col>
                <FormField label={"Hodnoceno v kat."} type={"number"} value={classified} setValue={setClassified} controlId={"classified"} />
              </Col>
            </Row>}
            <Row>
              <Col className="text-center">
                <Button onClick={calculateRankPoints}>Spočítat body</Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Title>{rankPoints !== -1 ? rankPoints : " "}</Modal.Title>
        </Modal.Footer>
      </Modal>
    </PageContainer>
  );

  function calculateRankPoints() {
    const CZ = durationTimeToFloat(timeMe);
    const PC = ((durationTimeToFloat(timeFirst) + durationTimeToFloat(timeSecond) + durationTimeToFloat(timeThird)) / 3.);
    const handicapCoef = classPointCalc.KS === 0 ? 1 : (1 - classPointCalc.KS * (myPlace - 1) / (classified - 1));
    setRankPoints(Math.round(Math.max(0, (2 - CZ/PC) * classPointCalc.PB * handicapCoef * classPointCalc.RK)));
  }

  function RankTable() {
    if (data.stats.length > 0) {
      return (
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            <th>Kategorie</th>
            <th>Průměrné body</th>
            <th title="Koeficient akce / kategorie">RK</th>
            <th title="Koeficient startu z hendikepu">KS</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <TableContent />
          </tbody>
        </Table>
      );
    }
    return (<p><i>Žádná data</i></p>);
    function TableContent() {
      const TableRow = ({element}) => {
        return (
          <tr>
            <td>{element.name}</td>
            <td>{element.PB}</td>
            <td>{element.RK}</td>
            <td>{element.KS}</td>
            <td className="td-w-icon" onClick={() => setClassPointCalc(element)}><FontAwesomeIcon icon={faCalculator} className="link-like"/></td>
          </tr>
        )
      }
      if (data.stats !== undefined) {
        const rows = [];
        for (var element in data.stats){
          rows.push(<TableRow element={data.stats[element]} key={element} />);
        }
        return (rows);
      }
    }
  }
};

export default EventRank;