import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AutoFormat from "../visual/AutoFormat";
import React, {useState} from "react";
import WarningCard from "../overlays/WarningCard";
import {labels} from "../../themeLabels";
import Button from "react-bootstrap/Button";
import VehicleIcon from "../visual/VehicleIcon";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";


const VehicleList = ({eventId, vehicles, user=undefined, editCrew=undefined, assignable=[], removable=[], processing}) => {
  const [vehicleChanging, setVehicleChanging] = useState(null);

  const VehicleTitle = ({vehicle}) => {
    const myTransportLink = "/akce/" + eventId + "/doprava"
    if (vehicle.my_vehicle)
      return (<b><Link to={myTransportLink}><VehicleIcon title={vehicle.title} /></Link> <Link to={myTransportLink}>{vehicle.title}</Link></b>);
    return (<b><VehicleIcon title={vehicle.title} /> {vehicle.title}</b>);
  }

  const Crew = ({people}) => {
    const crew = [];
    for (const i in people) {
      const person = people[i];
      if(person.full_name != null){
        crew.push(
          <Card key={person.id} className="mt-2">
            <p className="m-1 ms-2 me-2">{person.full_name}</p>
          </Card>
        );
      }else{
        crew.push(<Card className="mt-2" style={{borderStyle: "dotted"}}>
          <i className="m-1">Volno</i>
        </Card>);
      }
    }
    return crew;
  }

  const res = [];
  for (const i in vehicles) {
    const vehicle = vehicles[i];
    const freeSpaces = vehicle.capacity - vehicle.crew.length;
    res.push(
      <Col xs={12} sm={6} md={4} lg={3} xxl={2} className="ps-1 pe-1" key={vehicle.id}>
        <Card className="mb-2">
          <Card.Body>
            <p className="mb-1"><VehicleTitle vehicle={vehicle}/></p>
            {vehicle.driver != null && <Card key={vehicle.driver.id} className="mt-2 mb-2">
              <p className="m-1 ms-2 me-2">{vehicle.driver?.full_name}</p>
            </Card>}
            <div>
              <FontAwesomeIcon icon={faCircleInfo} color="gray"/> <AutoFormat text={vehicle.info}/>
            </div>
            <p className="mb-1"></p>
            <div className="mb-3">
              <Crew people={vehicle.crew}/>
            </div>
            {(labels.CondMyTransportSelf && user?.id) && <div>
              <hr/>
              <p className="mb-1">Volná místa: {freeSpaces}</p>
              <Row>
                {user?.vehicle ?
                  (vehicle.id === user.vehicle && vehicle.driver?.id !== user?.id) && <Col xs="auto" className="pe-1">
                    <Button disabled={processing} onClick={() => {
                      if (editCrew) editCrew(vehicle.id, user?.id);
                    }}>Odebrat se</Button>
                  </Col> :
                  <>
                    {freeSpaces > 0 && <Col xs="auto"><Button disabled={processing} onClick={() => {
                      if (editCrew) editCrew(vehicle.id, user?.id);
                    }}>Přidat se</Button></Col>}
                  </>
                }
                {(assignable.length > 0 || removable.length > 0) && <Col className="text-end">
                  <Button disabled={processing} variant="outline-primary" onClick={() => {
                    setVehicleChanging(vehicle);
                  }}>Další</Button>
                </Col>}
              </Row>
            </div>}
          </Card.Body>
        </Card>
      </Col>
    );
  }
  if (res.length < 1)
    return <WarningCard text="Žádná vozidla"/>;
  return <>
    {res}
    <ChangeCrew />
  </>;

  function ChangeCrew() {
    const freePlaces = vehicleChanging?.capacity - vehicleChanging?.crew?.length;
    return (
      <Modal show={vehicleChanging != null} onHide={()=>setVehicleChanging(null)}
             size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Změna posádky
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-1"><b>Členové posádky vozidla "{vehicleChanging?.title}"</b></p>
          <PeopleTable data={removable} crewMode={true}/>
          <p className="text-center">volná místa: <b style={{color: freePlaces === 0 ? "red" : "inherit"}}>{freePlaces}</b></p>
          <hr className="mb-2"/>
          <hr className="mt-2"/>
          <p className="mb-1"><b>Nepřiřazení</b></p>
          <PeopleTable data={assignable} crewMode={false}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>setVehicleChanging(null)}>Zavřít</Button>
        </Modal.Footer>
      </Modal>
    )

    function PeopleTable({data, crewMode}) {
      function selectEditable() {
        if (!crewMode)
          return data;
        return vehicleChanging?.crew;
      }

      if (!crewMode && (vehicleChanging?.capacity - vehicleChanging?.crew?.length) === 0)
        return <i>Žádná volná místa v posádce</i>;

      const removableIds = [];
      for (const i in removable)
        removableIds.push(removable[i].id);

      const rows = [];
      const filteredData = selectEditable();
      for(let i in filteredData){
        const person = filteredData[i];
        rows.push(
          <tr key={person.id}>
            <td>{person.reg_number}</td>
            <td>{person.full_name}</td>
            <td>{(!crewMode || (crewMode && removableIds.includes(person.id))) && <Button onClick={() => {
              if (editCrew) {
                setVehicleChanging(null);
                editCrew(vehicleChanging.id, person.id);
              }
            }}>{crewMode ? "Odebrat" : "Přidat"}</Button>}</td>
          </tr>
        );
      }

      if (rows.length === 0)
        return <i>Žádní uživatelé k {crewMode ? "odebrání" : "přidání"}</i>
      return(
        <Table>
          <tbody>
          <tr><td/><td/><td/></tr>
          {rows}
          </tbody>
        </Table>
      )
    }
  }
}

export default VehicleList;