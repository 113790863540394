import {getMyEvents} from "../../dao";
import Loader from "../../components/overlays/Loader";
import React, { useState, useEffect } from 'react';
import Table from "react-bootstrap/Table";
import {Link, useParams} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faCalendar, faFlagCheckered} from "@fortawesome/free-solid-svg-icons";
import {YearOptions, handleErrorLoading, parseDateBreakYear, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import iconoris from "../../static/iconoris.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {MobileWidthLimitSM, ORIS_URL} from "../../helpers/Constants";
import FormSelectArray from "../../components/form/FormSelectArray";
import {labels} from "../../themeLabels";

const MyRaces = () => {
  const { user } = useParams();
  const [year, setYear] = useState((new Date()).getFullYear());
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= MobileWidthLimitSM);

  useEffect(() => {
    const handleOrientationChange = () => {setIsMobile(window.innerWidth <= MobileWidthLimitSM)};
    window.addEventListener('resize', handleOrientationChange);
    return () => {window.removeEventListener('resize', handleOrientationChange)};
  }, []);

  useEffect(() => {
    getMyEvents(user, year)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [user, year]);

  if (loading)
      return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText(user == null ? 'Moje závody' : data?.profile?.full_name);
  return (
    <PageContainer>
      {user == null ? <h1>Moje závody</h1> : <h1>Závody: {data?.profile?.full_name}</h1>}
      <h3><FontAwesomeIcon icon={faCalendar} /> Budoucí</h3>
      <FutureEventsTable />
      <Row className="align-items-baseline mb-2">
        <Col xs="auto"><h3><FontAwesomeIcon icon={faFlagCheckered}/> Proběhlé</h3></Col>
        <Col className="ps-4">
          <FormSelectArray label={"Rok"} inline={true} size="sm" value={year} setValue={setYear} options={YearOptions(true)} className="mb-1" controlId={"year"} />
        </Col>
      </Row>
      <PastEventsTable />
    </PageContainer>
  );

  function PastEventsTable() {
    if (data?.past?.length > 0) {
      return (
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            {isMobile ? <></> : <th>Datum</th>}
            <th>Akce</th>
            {isMobile ? <th>Kat.</th> : <th>Kategorie</th> }
            {isMobile ? <th>Výsl.</th> : <><th>Místo</th><th>Čas</th><th>Ztráta</th><th>Rank</th></> }
          </tr>
          </thead>
          <tbody>
          <TableContent />
          </tbody>
        </Table>
      );
    }
    return (<p><i>Žádné závody</i></p>);
    function TableContent() {
      const TableRow = ({element}) => {
        const ResultCell = () => {
          return (<>
            {element.place !== "" && <><b>{element.place}.</b><br/></>}
            {element.time}<br/>
            {element.loss !== "" && <>({element.loss})<br/></>}
            {element.ranking != null && <i>{element.ranking}</i>}
          </>)
        }

        const orisPage = element?.is_relay ? "VysledkyStafet?id=" : "Vysledky?id=";
        const url = ORIS_URL + orisPage + element.oris_id + "&class=" + element.class_id;
        const icon = (<a href={url} target="_blank" rel="noreferrer" >
          <img
            src={iconoris}
            height="16"
            className="d-inline-block align-center"
            alt="ORIS"
          />
        </a>)
        return (
          <tr>
            {isMobile ? <td>{parseDateBreakYear(element.date)}<br/>
              <Link to={`/akce/${element.id}`} className="no-decoration">{element.title}</Link></td> : <>
              <td>{parseDateBreakYear(element.date)}</td>
              <td><Link to={`/akce/${element.id}`} className="no-decoration">{element.title}</Link></td>
            </>}
            {element.place > 0 || element.time === 'DISK' ?
              <td><Link target="_blank" to={url} className="no-decoration">{element.class_name}</Link> {icon}</td> :
              <td>{element.class_name}</td>
            }
            {isMobile ?
              (element.place > 0 ?
                <td align="justify"><ResultCell /></td> :
                <td>{element.time}</td>) :
              <><td>{element.place > 0 ? element.place + "." : ""}</td><td>{element.time}</td><td>{element.loss}</td><td><i>{element.ranking}</i></td></>
            }
          </tr>
        )

      }
      if (data?.past !== undefined) {
        const rows = [];
        for (const i in data?.past){
          rows.push(<TableRow element={data?.past[i]} key={i} />);
        }
        return (rows);
      }
    }
  }

  function FutureEventsTable() {
    if (data?.future?.length > 0) {
      return (
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            {isMobile ? <></> : <th>Datum</th>}
            <th>Akce</th>
            {isMobile ? <th>Kat.</th> : <><th>Kategorie</th><th>Start</th></> }
            {isMobile ? <th></th> : <th>Čip</th> }
          </tr>
          </thead>
          <tbody>
          <TableContent />
          </tbody>
        </Table>
      );
    }
    return (<p><i>Žádné závody</i></p>);
    function TableContent() {
      const TableRow = ({element}) => {
        const [showDetail, setShowDetail] = useState(false);
        function changeState(){
          setShowDetail(current => !current);
        }
        const url = (element.start_time == null ?
          ORIS_URL + "PrehledPrihlasenych?id=" + element.oris_id + "&class=" + element.class_id :
          ORIS_URL + "Startovka?id=" + element.oris_id + "&class=" + element.class_id)
        return (<>
          <tr>
            {isMobile ? <td>{parseDateBreakYear(element.date)}<br/>
              <Link to={`/akce/${element.id}`} className="no-decoration">{element.title}</Link></td> : <>
              <td>{parseDateBreakYear(element.date)}</td>
              <td><Link to={`/akce/${element.id}`} className="no-decoration">{element.title}</Link></td>
            </>}
            {element.oris_id == null ? <td>{element.class_name}</td> :
              <td>{isMobile && element.start_time != null && <>{element.start_time}<br/></>}<Link target="_blank" to={url} className="no-decoration">{element.class_name}</Link></td>}
            {isMobile ? <></> : <td>{element.start_time}</td>}
            {isMobile ? <td align={"center"} onClick={changeState}><FontAwesomeIcon icon={showDetail ? faAngleUp : faAngleDown}/></td> : <td>{element.si_number}</td> }
          </tr>
          {!showDetail ? (<></>) : (<tr><td colSpan="5" align={"justify"}>Čip: {element.si_number}</td></tr>)}
        </>)
      }
      if (data?.future !== undefined) {
        const rows = [];
        for (const i in data?.future){
          rows.push(<TableRow element={data?.future[i]} key={i} />);
        }
        return (rows);
      }
    }
  }
};

export default MyRaces;