import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getMyRights} from "../../dao";
import Loader from "../../components/overlays/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCog, faCoins, faGavel, faMedal, faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {isMobileDevice} from "../../helpers/Device";
import {handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageContainer from "../../layout/PageContainer";
import {labels} from "../../themeLabels";


const MyRights = () => {
  const { user } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  function loadData(){
    setLoading(true);
    getMyRights(user)
      .then((response) => {
        if (response.ok)
          return response.json();
        setErrorRemote(handleErrorLoading(response));
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) =>{
        console.log(err);
      });
  }

  if (loading)
    return <Loader loading={loading} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  const link = (user == null ? "/ucet/prava/" : "/ucet/"+user+"/prava/");
  setBrowserTabText(user == null ? 'Práva' : 'Práva | ' + data?.user?.full_name);
  return (
    <PageContainer>
      <Row className="align-items-center">
        <Col sm md={8}>
          {user == null ? <h1><FontAwesomeIcon icon={faPeopleArrows}/> Moje oprávnění a práva</h1> :
          <h1><FontAwesomeIcon icon={faPeopleArrows}/> Oprávnění a práva: {data?.user?.full_name}</h1>}
        </Col>
        <Col sm md={4} className="text-end">
          <Link to={link}><Button className="mb-3"><FontAwesomeIcon icon={faGavel}/> Nastavení práv</Button></Link>
        </Col>
      </Row>
      <br/>
      <h3>{user == null ? "Tvá p" : "P"}řihlašovací práva</h3>
      <MyRightsTable/>
      <p>Oprávnění pro přihlašování ti může udělit každý uživatel v nastavení práv, případně administrátor.</p>
      <hr/>
      <WardsOrGuardian />
      <hr/>
      <h3>Uživatelé s přihlašovacími právy {user == null ? "ke tvému" : "k tomuto"} účtu</h3>
      <RightsTable/>
    </PageContainer>
  )

  function MyRightsTable() {
    if (data.my_rights.length < 1) {
      return (<><p><i>Žádní
        uživatelé {user == null ? "ti neudělili přihlašovací práva." : "neudělili přihlašovací práva tomuto účtu."}</i>
      </p><br/></>)
    }
    const rows = [];
    data.my_rights.forEach((user)=>{
      rows.push(<tr key={user.id}>
        <td>{user.reg_number}</td>
        <td>{user.full_name}</td>
        <td align={"center"}><Link to={'/ucet/'+user.id+'/zavody/'}><FontAwesomeIcon icon={faMedal} size="lg"/></Link></td>
        <td align={"center"}><Link to={'/ucet/'+user.id+'/nastaveni/'}><FontAwesomeIcon icon={faCog} size="lg"/></Link></td>
      </tr>);
    });

    return (
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th>Reg.</th>
          <th>Jméno</th>
          {isMobileDevice() ? <th>Z.</th> : <th>Závody</th> }
          {isMobileDevice() ? <th>N.</th> : <th>Nast.</th> }
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }

  function WardsOrGuardian() {
    if (data.guardian == null)
      return (<>
        <h3>Přidružené účty</h3>
        {data.wards.length >= 1 ?
          <p>Všechny náklady přidružených účtů se přičítají k tvému účtu. Pro přidání přidruženého účtu kontaktuj
            administátora. Odebrání přidruženého účtu se projeví <b style={{color: "red"}}>až ke konci nejbližšího
              roku</b>.</p> :
          <p>Přidružení účtu slouží ke společnému vyúčtování. Náklady přidružených účtů se sčítají na nadřazeném účtu. Pro přidání přidruženého účtu kontaktuj
            administátora, změna se projeví <b style={{color: "red"}}>až ke konci nejbližšího
              roku</b>.</p>}
        <MyWardsTable/>
      </>)
    return (<>
      <h3>Nadřazený účet</h3>
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th>Reg.</th>
          <th>Jméno</th>
          <th>Platnost</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{data.guardian.reg_number}</td>
          <td>{data.guardian.full_name}</td>
          <td>{data.guardian.year_from} – {data.guardian.year_to}</td>
        </tr>
        </tbody>
      </Table>
      <p>Všechny náklady tvého účtů se přičítají k nadřazenému účtu. Odebrání nadřazeného účtu se projeví <b
        style={{color: "red"}}>až ke konci nejbližšího roku</b>.</p>
    </>)
  }

  function MyWardsTable() {
    if (data.wards.length < 1) {
      return (<><p><i>Žádní
        uživatelé nejsou v letošním roce přidruženi k {user == null ? "tvému" : "tomuto"} účtu.</i></p><br/></>)
    }
    const rows = [];
    data.wards.forEach((user) => {
      rows.push(<tr key={user.id}>
        <td>{user.reg_number}</td>
        <td>{user.full_name}</td>
        <td>{user.year_from} – {user.year_to}</td>
        <td align={"center"}>
          <Link to={'/ucet/' + user.id + '/zavody/'}><FontAwesomeIcon icon={faMedal} size="lg"/></Link>
        </td>
        <td align={"center"}>
          {labels.CondShowUserBilling && <Link to={'/ucet/' + user.id + '/vyuctovani/'}><FontAwesomeIcon icon={faCoins} size="lg"/></Link>}
        </td>
        <td align={"center"}>
          <Link to={'/ucet/' + user.id + '/nastaveni/'}><FontAwesomeIcon icon={faCog} size="lg"/></Link>
        </td>
      </tr>);
    });

    return (
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th>Reg.</th>
          <th>Jméno</th>
          {isMobileDevice() ? <th>Plat.</th> : <th>Platnost</th>}
          {isMobileDevice() ? <th>Z.</th> : <th>Závody</th> }
          {isMobileDevice() ? <th>V.</th> : <th>Vyúč.</th> }
          {isMobileDevice() ? <th>N.</th> : <th>Nast.</th> }
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }

  function RightsTable(){
    if(data.rights_settings.length < 1){
      return(<><p><i>Žádní uživatelé kromě trenérů nemají práva {user == null ? "tě přihlašovat." : "přihlašovat tohoto uživatele."}</i></p><br/></>)
    }
    const rows = [];
    data.rights_settings.forEach((user)=>{
      rows.push(<tr key={user.id}>
        <td>{user.reg_number}</td>
        <td>{user.full_name}</td>
      </tr>);
    });

    return (
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th>Reg.</th>
          <th>Jméno</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }

}

export default MyRights;