import React, { useState } from "react";
import { useEffect } from "react";
import {deleteServiceOrder, getServices, postOrder, updateServiceOrder} from "../../dao";
import { useParams } from "react-router-dom";
import Loader from "../../components/overlays/Loader";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCartPlus, faPencil, faTrash, faUsers} from '@fortawesome/free-solid-svg-icons';
import {handleErrorLoading, handleErrorSubmit, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import { Button, Form, Modal } from "react-bootstrap";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import PageHeading from "../../layout/PageHeading";
import WarningCard from "../../components/overlays/WarningCard";
import Alert from "react-bootstrap/Alert";
import {isMobileDevice} from "../../helpers/Device";
import NoYesIndicator from "../../components/visual/NoYesIndicator";
import FormField from "../../components/form/FormField";
import SearchBar from "../../components/form/SearchBar";
import {HomeClub} from "../../helpers/Constants";
import FormCheckbox from "../../components/form/FormCheckbox";
import {labels} from "../../themeLabels";

const EventServices = () => {
  const { eventId } = useParams();
  const [data, setData] = useState({services: [], updated: false});
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [serviceToBuy, setServiceToBuy] = useState(null);
  const [buyCount, setBuyCount] = useState(1);
  const [buyNote, setBuyNote] = useState("");
  const [buyForClub, setBuyForClub] = useState(false);
  const [buyMember, setBuyMember] = useState(null);
  const [buyManualFee, setBuyManualFee] = useState(0);
  const [selectMember, setSelectMember] = useState(false);
  const [orderToEdit, setOrderToEdit] = useState(null);
  const [orderToDelete, setOrderToDelete] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    setLoading(true);
    getServices(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {services: [], updated: false};
        }
        return response.json()})
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />
  if (loading)
    return <Loader />;

  setBrowserTabText('Doplňkové služby | ' + data?.event?.title);
  return (
    <PageContainer>
      <PageHeading heading={"Doplňkové služby: " + data?.event?.title} id={eventId} />
      {!data.updated && <Alert variant="warning">Informace o službách se nepodařilo aktualizovat, počty míst nemusí odpovídat realitě</Alert>}
      <PageContent />
      <Modal show={buyMember != null} onHide={()=>setBuyMember(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Objednat službu <b>{serviceToBuy?.title}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormField label={"Objednávající"} value={buyMember?.reg_number + " – " + buyMember?.full_name} readOnly={true} controlId={"buyer"} />
            <FormField label={"Počet"} value={buyCount} setValue={setBuyCount} minNumberVal={0} maxNumberVal={serviceToBuy?.available} type="number" controlId={"quantity"} />
            <FormField label={"Poznámka"} value={buyNote} setValue={setBuyNote} valueLimit={64} digits={"none"} controlId={"note"} />
            {data?.logged?.perms?.edit_events && <FormCheckbox label={"Platí klub"} value={buyForClub} setValue={setBuyForClub} controlId={"paid"} />}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setBuyMember(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={buyService}>
            Objednat
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={orderToEdit != null} onHide={nullOrderToEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Změnit objednávku <b>{orderToEdit?.title}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mb-1">
            <FormField label={"Objednávající"} value={orderToEdit?.profile?.reg_number + " – " + orderToEdit?.profile?.full_name} readOnly={true} controlId={"buyer"} />
            <FormField label={"Počet"} value={buyCount} setValue={setBuyCount} readOnly={!orderToEdit?.can_edit} minNumberVal={0}
                       maxNumberVal={serviceToBuy == null ? orderToEdit?.available + orderToEdit?.quantity : serviceToBuy?.available} type="number" controlId={"quantity"} />
            <FormField label={"Poznámka"} value={buyNote} setValue={setBuyNote} valueLimit={64} digits={"none"} controlId={"note"} />
            {(data?.logged?.perms?.edit_events || data?.logged?.perms?.use_billing) && <FormCheckbox label={"Platí klub"} value={buyForClub} setValue={setBuyForClub} controlId={"paid"} />}
            {data?.logged?.perms?.use_billing && <Table responsive borderless>
                <thead>
                <tr>
                  <th></th>
                  <th>Cena</th>
                  <th>ks</th>
                  <th>Celkem</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td title="Vypočteno z Orisu">Oris:</td>
                  <td>{orderToEdit?.unit}</td>
                  <td>{orderToEdit?.quantity}</td>
                  <td>{orderToEdit?.fee}</td>
                </tr>
                {orderToEdit?.is_edited && <tr>
                  <td title="Manuálně nastaveno">Man:</td>
                  <td></td>
                  <td></td>
                  <td>{orderToEdit?.manual_fee}</td>
                </tr>}
                <tr>
                  <th colSpan={4}>Nastavit manuálně</th>
                </tr>
                <tr>
                  <td></td>
                  <td colSpan={3}>
                    <FormField label={""} value={buyManualFee} setValue={setBuyManualFee} type="number" controlId={"manualFee"} />
                  </td>
                </tr>
                </tbody>
              </Table>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={nullOrderToEdit}>Zavřít</Button>
          {data?.logged?.perms?.use_billing && <Button variant="outline-primary" onClick={() => {editOrder(0)}}>Reset</Button>}
          <Button variant="primary" onClick={() => editOrder()}>Změnit</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={orderToDelete != null} onHide={() => setOrderToDelete(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Smazat objednávku <b>{orderToDelete?.title}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mb-3">
            <FormField label={"Objednávající"} value={orderToDelete?.profile?.reg_number + " – " + orderToDelete?.profile?.full_name} readOnly={true} controlId={"buyer"} />
            <FormField label={"Počet"} value={orderToDelete?.quantity} readOnly={true} type="number" controlId={"quantity"} />
            <FormField label={"Poznámka"} value={orderToDelete?.note} readOnly={true} digits={"none"} controlId={"note"} />
            {data?.logged?.perms?.edit_events && <FormCheckbox label={"Platí klub"} value={buyForClub} setValue={setBuyForClub} controlId={"paid"} />}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOrderToDelete(null)}>Zavřít</Button>
          <Button variant="primary" onClick={removeOrder}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <SelectMember
        show={selectMember}
        onHide={() => {setSelectMember(false);}}
        people={data.members}
      />
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>);

  function nullOrderToEdit() {
    setOrderToEdit(null);
    setBuyCount(1);
    setBuyNote("");
    setBuyForClub(false);
  }

  function PageContent() {
    if (data.services.length === 0)
      return <WarningCard text={"Žádné doplňkové služby k dispozici"} />;
    return (<>
      <h4>Služby nabízené pořadatelem</h4>
      <ServiceTable/>
      <p><i>Pokud máš zájem o společné ubytování, neobjednávej si jej sám/sama zde, vyber pouze příslušnou variantu ve své přihlášce.</i></p>
      <h4>Klubové objednávky</h4>
      <OrderTable/>
    </>)

    function ServiceTable(){
      const rows = [];
      for (const i in data.services) {
        const service = data.services[i];
        const repletionCoef = Number(service.ordered) / Number(service.amount);
        const availableColor = repletionCoef >= 1 ? "red" : (repletionCoef >= 0.75 ? "orange" : "inherit");
        rows.push(<tr key={service.id}>
          <td>{service.title}</td>
          {!isMobileDevice() && <td>{service.count}</td>}
          <td>{service.unit} Kč</td>
          <td><i>{parseDateTime(service.term)}</i></td>
          <td title={"objednáno " + service.ordered + " / celkem " + service.amount}><b style={{color: availableColor}}>{service.available}</b></td>
          {(service.available > 0 && service.can_add && service.can_order) ? (<td align="center" title="Objednat pro sebe" onClick={() => {
            setBuyMember(data.logged);
            setServiceToBuy(service);
          }}><FontAwesomeIcon icon={faCartPlus} className="link-like" size="lg"/></td>) : (<td align="center"><FontAwesomeIcon icon={faCartPlus} color="gray" size="lg"/></td>)}
          {(service.available > 0 && service.can_order) ? (<td align="center" title="Objednat pro někoho" onClick={() => {
            setSelectMember(true);
            setServiceToBuy(service);
          }}><FontAwesomeIcon icon={faCartPlus} className="link-like" size="sm"/><FontAwesomeIcon icon={faUsers} className="link-like" size="sm"/></td>) : (<td align="center"><FontAwesomeIcon icon={faCartPlus} color="gray" size="sm"/><FontAwesomeIcon icon={faUsers} color="gray" size="sm"/></td>)}
        </tr>)
      }

      return (
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            <th>Popis</th>
            {!isMobileDevice() && <th>{HomeClub} ks</th>}
            <th>Cena</th>
            <th>Termín</th>
            <th>Volno</th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      )
    }

    function OrderTable(){
      if (data.orders.length === 0)
        return <i>Žádné klubové objednávky nebyly dosud provedeny.</i>;
      var rows = [];
      data.orders.forEach(order => {
        rows.push(<tr key={order.id}>
          <td>{order.title}</td>
          <td>{isMobileDevice() ? <>{order?.profile?.full_name}<br/><i>{order?.profile?.reg_number}</i></> : order?.profile?.reg_number + " – " + order?.profile?.full_name}</td>
          <td>{order.quantity}</td>
          {(data?.logged?.perms?.edit_events || data?.logged?.perms?.use_billing) && <>
            <td>{order?.is_edited ? order.manual_fee : order.fee}</td>
            <td align="center" title="Hrazeno klubem"><NoYesIndicator condition={order.paid_by_club}/></td>
          </>}
          {(order.can_edit || data?.logged?.perms?.use_billing) ? (<td className="pointing td-w-icon" onClick={() => {
            setOrderToEdit(order);
            setBuyCount(order.quantity);
            setBuyNote(order.note);
            setBuyForClub(order?.paid_by_club);
            setBuyManualFee(order?.is_edited ? order?.manual_fee : order?.fee);
          }}><FontAwesomeIcon icon={faPencil} className={order?.is_edited ? "link-like-marked" :"link-like"}/></td>) : (<td></td>)}
          {order.can_edit ? (<td className="pointing td-w-icon" onClick={() => {
            setOrderToDelete(order);
          }}><FontAwesomeIcon icon={faTrash} className="link-like"/></td>) : (<td></td>)}
        </tr>)
      });

      return (
        <Table striped bordered hover={labels.CondElemTableHover} responsive>
          <thead>
          <tr>
            <th>Služba</th>
            <th>Autor</th>
            <th>{isMobileDevice() ? "ks" : "Počet ks"}</th>
            {(data?.logged?.perms?.edit_events || data?.logged?.perms?.use_billing) && <>
              <th>Cena</th>
              <th title="Hrazeno klubem"></th>
            </>}
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      )
    }
  }

  function buyService(){
    const formData = {
      "person": buyMember.id,
      "quantity": buyCount,
      "note": buyNote,
      "paid_by_club": buyForClub
    }
    postOrder("service=" + serviceToBuy.id, formData)
      .then((res) => {
        if (res.ok)
          setSuccess("Služba objednána");
        else
          setError(handleErrorSubmit(res, "Nepodařilo se objednat"));
        setBuyMember(null);
        loadData();
      })
  }

  function editOrder(edited=1){
    if (buyCount === 0)
      return;
    const formData = {
      "edited": edited,
      "quantity": buyCount,
      "note": buyNote,
      "paid_by_club": buyForClub,
      "fee": buyManualFee
    }
    updateServiceOrder(orderToEdit.id, formData)
      .then((res) => {
        if (res.ok)
          setSuccess("Objednávka aktualizována");
        else
          setError(handleErrorSubmit(res, "Nepodařilo se změnit"));
        nullOrderToEdit();
        loadData();
      })
  }

  function removeOrder(){
    deleteServiceOrder(orderToDelete.id)
      .then((res) => {
        if (res.ok)
          setSuccess("Objednávka smazána");
        else
          setError(handleErrorSubmit(res, "Nepodařilo se smazat"));
        setOrderToDelete(null);
        loadData();
      })
  }

  function SelectMember(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Vybrat objednatele služby
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PeopleTable people={props.people}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setSelectMember(false)}>Zavřít</Button>
        </Modal.Footer>
      </Modal>
    );

    function PeopleTable(props){
      const [filteredPeople, setFilteredPeople] = useState(props.people);

      if(props.people?.length > 0){
        const rows = [];
        for(var i in filteredPeople){
          const person = filteredPeople[i];
          rows.push(
            <tr key={person.id}>
              <td>{person.reg_number}</td>
              <td>{person.full_name}</td>
              <td>
                <Button onClick={()=> {
                  setBuyMember(person);
                  setSelectMember(false);
                }}>Vybrat</Button>
              </td>
            </tr>
          );
        }
        return(
          <>
            <SearchBar data={props.people} setFilteredPeople={setFilteredPeople} />
            <Table>
              <tbody>
              {rows}
              </tbody>
            </Table>
          </>
        )
      }
      return(<p>Nenalezeny žádné možnosti.</p>);
    }
  }
};
  
export default EventServices;