import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import {SelectOptionsArray} from "./SelectOptions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import {textBreakLine} from "../../helpers/Functions";


const FormSelectArray = ({label="", value, setValue, options, disabled=false, inline=false, className="mb-3", size="", help=undefined, controlId}) => {
  const [showHelp, setShowHelp] = useState(false);

  const HelpIcon = () => {
    if (!help)
      return <></>;
    if (typeof help !== "string")
      return help;
    return <FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setShowHelp(true)} />;
  }

  const HelpModal = () => {
    return <Modal show={showHelp} onHide={() => setShowHelp(false)}>
      <Modal.Header closeButton>
        <Modal.Title><FontAwesomeIcon icon={faQuestionCircle} />&nbsp;{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof help === "string" && textBreakLine(help)}
      </Modal.Body>
    </Modal>
  }

  if (inline) {
    return (
      <Form.Group className={className} controlId={controlId}>
        <div style={{display: "flex"}}>
          {label !== "" && <Form.Label column={true} className="me-2">{label}: <HelpIcon/></Form.Label>}
          <Form.Select style={{fontSize: "0.9rem"}} value={value} disabled={disabled} size={size} onChange={(e) => setValue(e.target.value)}>
            <SelectOptionsArray options={options}/>
          </Form.Select>
        </div>
        <HelpModal/>
      </Form.Group>
    )
  }

  return (
    <Form.Group className={className} controlId={controlId}>
      {typeof label !== "string" ? label : (label !== "" && <Form.Label column={false}>{label}: <HelpIcon/></Form.Label>)}
      <Form.Select style={{fontSize: "0.9rem"}} value={value} disabled={disabled} size={size} onChange={(e) => setValue(e.target.value)}>
        <SelectOptionsArray options={options}/>
      </Form.Select>
      <HelpModal/>
    </Form.Group>
  )
}

export default FormSelectArray;