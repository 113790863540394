import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/home/Login";
import Billing from "./pages/user/Billing";
import MyRaces from "./pages/user/MyRaces";
import Documents from "./pages/club/Documents";
import Settings from "./pages/user/Settings";
import Club from "./pages/club/Club";
import Home from "./pages/home/Home";
import NoPage from "./pages/home/NoPage";
import ClubAdmin from "./pages/club/ClubAdmin";
import EventDetail from './pages/event/EventDetail';
import AddUser from './pages/club/AddUser';
import Enroll from './pages/event/Enroll';
import useUserData from './helpers/Auth';
import ClubEnrolls from './pages/event/ClubEnrolls';
import CreateTraining from './pages/club/CreateTraining';
import EditUsersCsv from './pages/club/EditUsersCsv';
import ClubEventAdmin from './pages/event/ClubEventAdmin'
import EventRank from './pages/event/EventRank';
import ClubRank from './pages/club/ClubRank';
import EventServices from './pages/event/EventServices';
import useColormode from './helpers/Colormode';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSun, faMoon, faTree, faUserGroup, faToolbox, faFile, faUser } from '@fortawesome/free-solid-svg-icons';
import TransportAdmin from './pages/event/TransportAdmin';
import RightsEdit from './pages/user/RightsEdit';
import Transport from './pages/event/Transport';
import ClubBilling from "./pages/club/ClubBilling";
import EventExport from "./pages/event/EventExport";
import MyRights from "./pages/user/MyRights";
import BillingSummary from "./pages/user/BillingSummary";
import Requisitions from "./pages/event/Requisitions";
import RequisitionEvents from "./pages/club/RequisitionEvents";
import Payments from "./pages/club/Payments";
import ClubBillingDetail from "./pages/club/ClubBillingDetail";
import EditEvent from './pages/event/EditEvent';
import CreateCamp from './pages/club/CreateCamp';
import EventEditors from "./pages/event/EventEditors";
import Help from "./pages/home/Help";
import BugReport from "./pages/misc/BugReport";
import EventLinks from "./pages/event/EventLinks";
import EventMyTransport from "./pages/event/EventMyTransport";
import PasswordReset from './pages/home/PasswordReset';
import BugsReported from "./pages/misc/BugsReported";
import UserStats from "./pages/user/UserStats";
import Cups from "./pages/home/Cups";
import Easter from './pages/misc/Easter';
import EasterAddPair from './pages/misc/EasterAddPair';
import EventWhiteList from './pages/event/EventWhitelist';
import CopyCampForm from "./pages/club/CopyCampForm";
import ServerSettings from "./pages/misc/ServerSettings";
import ClubStats from "./pages/club/ClubStats";
import ServerEmailQueue from "./pages/misc/ServerEmailQueue";
import ClubBillingYears from "./pages/club/ClubBillingYears";
import ForeignEvents from "./pages/club/ForeignEvents";
import ClubAdminSpecial from "./pages/club/ClubAdminSpecial";
import ServerAsyncRuns from "./pages/misc/ServerAsyncRuns";
import PostAddEdit from "./pages/club/PostAddEdit";
import PostDashboard from "./pages/club/PostDashboard";
import Post from "./pages/club/Post";
import ServerActionLog from "./pages/misc/ServerActionLog";
import UserRoles from "./pages/club/UserRoles";
import UserGroups from "./pages/club/UserGroups";
import UserMemberships from "./pages/club/UserMemberships";
import MembershipHistory from "./pages/user/MembershipHistory";
import IndexOfTerms from "./pages/home/IndexOfTerms";
import MyBugReports from "./pages/misc/MyBugReports";
import EventClubOffers from "./pages/event/EventClubOffers";
import EnrollAnonym from "./pages/event/EnrollAnonym";


function App() {
  const { userData, setUserData } = useUserData();
  library.add(faSun, faMoon, faTree, faUserGroup, faToolbox, faFile, faUser); //adding icons
  useColormode().initColorMode();
  if (!userData && window.location.pathname !== "/reset" && (!window.location.pathname.includes("anonym"))) {
    return <Login setUserData={setUserData} />;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="" element={<Home />} />
          <Route path='klub' >
            <Route path='' element={<Club />} />
            <Route path='ranking' element={<ClubRank />} />
            <Route path='statistiky' element={<ClubStats />}/>
            <Route path='prispevek/:postId'>
              <Route path='' element={<Post />} />
              <Route path='upravit' element={<PostAddEdit />} />
            </Route>
            <Route path='nastenka'>
              <Route path='' element={<PostDashboard />}/>
              <Route path='pridat' element={<PostAddEdit />} />
            </Route>
          </Route>
          <Route path='ucet'>
            <Route path='nastaveni' element={<Settings />} />
            <Route path='zavody' element={<MyRaces />} />
            <Route path='' element={<UserStats />}/>
            <Route path='statistiky' element={<UserStats />} />
            <Route path='clenstvi' element={<MembershipHistory />} />
            <Route path='vyuctovani' element={<BillingSummary />} />
            <Route path='vyuctovani/detail' element={<Billing />} />
            <Route path='prava' element={<RightsEdit />} />
            <Route path='opravneni' element={<MyRights />} />
          </Route>
          <Route path='ucet/:user'>
            <Route path='nastaveni' element={<Settings />}/>
            <Route path='zavody' element={<MyRaces />}/>
            <Route path='' element={<UserStats />}/>
            <Route path='statistiky' element={<UserStats />}/>
            <Route path='clenstvi' element={<MembershipHistory />} />
            <Route path='prava' element={<RightsEdit />} />
            <Route path='opravneni' element={<MyRights />} />
            <Route path='vyuctovani'>
              <Route path='' element={<BillingSummary />} />
              <Route path='detail' element={<Billing />} />
              <Route path='detail/:year' element={<Billing />} />
            </Route>
          </Route>
          <Route path='dokumenty' element={<Documents />} />
          <Route path='admin'>
            <Route path='' element={<ClubAdmin />} />
            <Route path='bugy' element={<BugsReported />} />
            <Route path='pridat'>
              <Route path='trenink' element={<CreateTraining />} />
              <Route path='soustredeni' element={<CreateCamp />} />
              <Route path='jina' element={<CreateCamp />} />
            </Route>
            <Route path='ucty'>
              <Route path='pridat' element={<AddUser />} />
              <Route path='upravit' element={<EditUsersCsv />} />
              <Route path='rozsirene' element={<ClubAdminSpecial />} />
              <Route path='role' element={<UserRoles />} />
              <Route path='clenstvi' element={<UserMemberships />} />
              <Route path='skupiny' element={<UserGroups />} />
            </Route>
            <Route path='server'>
              <Route path='log' element={<ServerActionLog />} />
              <Route path='email-queue' element={<ServerEmailQueue />} />
              <Route path='async-runs' element={<ServerAsyncRuns />} />
              <Route path='' element={<ServerSettings />} />
            </Route>
          </Route>
          <Route path='akce/:eventId'>
            <Route path='' element={<EventDetail />} />
            <Route path='prihlasky' element={<ClubEnrolls />} />
            <Route path='klub' element={<ClubEnrolls />} />
            <Route path='prihlaska/:userId' element={<Enroll />} />
            <Route path='prihlaska' element={<Enroll />} />
            <Route path='admin' element={<ClubEventAdmin />} />
            <Route path='editori' element={<EventEditors />} />
            <Route path='odkazy' element={<EventLinks />} />
            <Route path='pohledavky' element={<Requisitions />} />
            <Route path='rank' element={<EventRank />} />
            <Route path='sluzby' element={<EventServices />} />
            <Route path='upravit' element={<EditEvent />} />
            <Route path='nabidky' element={<EventClubOffers />} />
            <Route path='export' element={<EventExport />} />
            <Route path='whitelist' element={<EventWhiteList />}/>
            <Route path='kopirovat' element={<CopyCampForm />}/>
            <Route path='dvojice' element={<Easter />} />
            <Route path='dvojice/upravit' element={<EasterAddPair />} />
            <Route path='doprava'>
              <Route path='' element={<EventMyTransport />} />
              <Route path='detail' element={<Transport />} />
              <Route path='rozdeleni' element={<TransportAdmin />} />
            </Route>
          </Route>
          <Route path='anonym/akce/:eventId/prihlaska' element={<EnrollAnonym />} />
          <Route path='anonym/akce/:eventId/prihlaska/:userId' element={<EnrollAnonym />} />
          <Route path='ucetnictvi'>
            <Route path='' element={<ClubBilling />} />
            <Route path='platby' element={<Payments />} />
            <Route path='detail' element={<ClubBillingDetail />} />
            <Route path='obdobi' element={<ClubBillingYears />} />
            <Route path='pohledavky'>
              <Route path='' element={<RequisitionEvents />} />
              <Route path='cizi-meny' element={<ForeignEvents />} />
            </Route>
          </Route>
          <Route path='napoveda' element={<Help />} />
          <Route path='rejstrik' element={<IndexOfTerms />} />
          <Route path='bug' element={<BugReport />} />
          <Route path='bug/nahlasene' element={<MyBugReports />} />
          <Route path='zebricky' element={<Cups />} />
          <Route path='reset' element={<PasswordReset />} />
          <Route path='*' element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
