import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import {faCircleCheck, faClipboardCheck, faFlagCheckered, faLink, faListOl, faStopwatch} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "react-bootstrap/Button";
import {Badge} from "react-bootstrap";
import React from "react";
import {parseDateTimeSec, selectEnrollVariant} from "../../helpers/Functions";
import Table from "react-bootstrap/Table";
import EventClassLinks from "./EventClassLinks";
import {HomeClub, ORIS_URL} from "../../helpers/Constants";
import {labels} from "../../themeLabels";

const EventEnrollCard = ({event, showEnrollList, entryTransport}) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <EnrollStartResultContent />
      </Card.Body>
    </Card>)

  function EnrollStartResultContent() {
    const entry = event.entry;
    const EntryBadge = () => {
      if (event.type !== 0)
        return <></>;
      const EntryBadgeContent = ({variant, content}) => {
        const orisUrl = ORIS_URL + "PrehledPrihlasenych?id=" + event.oris_id;
        return <Link to={orisUrl} target="_blank"><Badge style={{fontSize: "0.9em"}} pill bg={variant}>{content}</Badge></Link>;
      }
      if (event?.entry_limit === 0)
        return <EntryBadgeContent variant={"secondary"} content={event.entry_count} />;
      const badgeContent = event.entry_count + "/" + event.entry_limit + "+" + event.entry_reserves;
      if (event.entry_count < event.entry_limit)
        return <EntryBadgeContent variant={"success"} content={badgeContent} />;
      if (event.entry_count < event.entry_limit + event.entry_reserves)
        return <EntryBadgeContent variant={"warning"} content={badgeContent} />;
      return <EntryBadgeContent variant={"danger"} content={badgeContent} />;
    }
    switch (event.term) {
      case -2:
        if (event.results && event.type === 0) {
          const orisPage = event?.is_relay ? "VysledkyStafet?id=" : "Vysledky?id=";
          return (<>
            <Card.Title><FontAwesomeIcon icon={faFlagCheckered} /> Výsledky</Card.Title>
            <MyResult />
            <Row>
              <Col xs="auto">
                <Link to={ORIS_URL + orisPage + event.oris_id + "&class=" + entry?.class_id}><b>{entry?.class_name}</b></Link>
              </Col>
              <Col xs="auto">
                <Link to={ORIS_URL + orisPage + event.oris_id + "&club=" + HomeClub}>{HomeClub} ({event.club_entries})</Link>
              </Col>
              <Col xs="auto">
                <Link to={ORIS_URL + orisPage + event.oris_id}>Celkové</Link>
              </Col>
            </Row>
          </>);
        }
        return (<>
          <Card.Title><Row className="align-items-center">
            <Col xs="auto" className="mb-0 pe-1"><FontAwesomeIcon icon={faClipboardCheck} /> Přihlašování </Col>
            <Col xs="auto" className="ps-0"><EntryBadge /></Col>
          </Row></Card.Title>
          <EnrolledMarker />
          <Row className="align-items-center">
            <MyEnroll />
            <Col xs="auto">
              <Row>
                <EnrollOthers />
                <Services />
              </Row>
            </Col>
          </Row>
          <StartListsContent />
        </>);
      case -1:
        return (<>
          <Card.Title><FontAwesomeIcon icon={faClipboardCheck} /> Přihlašování</Card.Title>
          <EnrollTerms />
          <p className="keep-all">Přihlašování ještě nezačalo</p>
        </>);
      case 0:
        return (<>
          <Card.Title>
            <Row className="align-items-center">
              <Col xs="auto" className="mb-0 pe-1"><FontAwesomeIcon icon={faClipboardCheck} /> Předběžné přihlašování </Col>
              <Col xs="auto" className="ps-0"><EntryBadge /></Col>
            </Row>
          </Card.Title>
          <EnrolledMarker />
          <EnrollTerms />
          <Row className="align-items-center">
            <MyEnroll />
            <Col xs="auto">
              <Row>
                <EnrollOthers />
              </Row>
            </Col>
          </Row>
        </>);
      case 1:
      case 2:
      case 3:
      case 4:
        return (<>
          <Card.Title>
            <Row className="align-items-center">
              <Col xs="auto" className="mb-0 pe-1"><FontAwesomeIcon icon={faClipboardCheck} /> Přihlašování </Col>
              <Col xs="auto" className="ps-0"><EntryBadge /></Col>
            </Row>
          </Card.Title>
          <EnrolledMarker event={event} />
          <EnrollTerms event={event} />
          <Row className="align-items-center">
            <MyEnroll event={event} entryTransport={entryTransport} />
            <Col xs="auto">
              <Row>
                <EnrollOthers />
                <Services />
              </Row>
            </Col>
          </Row>
        </>);
      default:
        return <></>;
    }

    function MyResult() {
      const MyResultContent = ({entry}) => {
        return (<>
          {entry.place !== "" && <Col xs="auto" style={{marginRight: "-5px"}}><b>{entry.place}.</b></Col>}
          <Col xs="auto" style={{marginRight: "-5px"}}>{entry.time}</Col>
          {entry.loss !== "" && <Col xs="auto" style={{marginRight: "-5px"}}>({entry.loss})</Col>}
          {entry.ranking != null && <Col xs="auto" style={{marginRight: "-5px"}}><i>{entry.ranking}</i></Col>}
        </>)
      }
      if (entry.time != null && entry.time !== '') {
        const variant = entry.place !== "" ? "primary" : "secondary";
        return (<>
          <p className="mb-2">Můj výsledek v kategorii {entry.class_name}:</p>
          <Alert variant={variant}>
            <Row>
              <MyResultContent entry={entry} />
            </Row>
          </Alert>
          <Table responsive borderless className="mb-1">
            <tbody>
            <tr>
              <EventClassLinks eventId={event.oris_id} classId={entry.class_id} className={<FontAwesomeIcon icon={faLink}/>} startlists={event?.startlists} results={event?.results} isTeam={event?.is_relay} />
            </tr>
            </tbody>
          </Table>
        </>);
      }
      return <></>;
    }

    function StartListsContent() {
      if (!event?.startlists || event.type !== 0) {
        if (event?.is_relay && event.term === -2) {
          return <>
            <hr/>
            <Card.Title><FontAwesomeIcon icon={faListOl} /> Soupisky</Card.Title>
            <Row>
              <Col xs="auto">
                <Link to={ORIS_URL + `PrehledPrihlasenych?id=${event.oris_id}&class=${entry?.class_id}&teams=1`} target="_blank"><b>{entry?.class_name}</b></Link>
              </Col>
              <Col xs="auto">
                <Link to={ORIS_URL + `PrehledPrihlasenych?id=${event.oris_id}&club=${HomeClub}&teams=1`} target="_blank">Klubové</Link>
              </Col>
              <Col xs="auto">
                <Link to={ORIS_URL + `PrehledPrihlasenych?id=${event.oris_id}&teams=1`} target="_blank">Celkové</Link>
              </Col>
            </Row>
          </>;
        }
        return <></>;
      }
      return <>
        <hr/>
        <Card.Title><FontAwesomeIcon icon={faStopwatch} /> Startovky</Card.Title>
        <Row>
          <Col xs="auto">
            <Link to={ORIS_URL + "Startovka?id=" + event.oris_id + "&class=" + entry?.class_id}><b>{entry?.class_name}</b></Link>
          </Col>
          <Col xs="auto">
            <Link to={`/akce/${event.id}/prihlasky`}>Klubové ({event.club_entries})</Link>
          </Col>
          <Col xs="auto">
            <Link to={ORIS_URL + "Startovka?id=" + event.oris_id}>Celkové</Link>
          </Col>
        </Row>
      </>;
    }

    function EnrolledMarker() {
      const EnrollDetail = ({event}) => {
        const entry = event.entry;
        let url = (entry.start_time != null ?
          ORIS_URL + "Startovka?id="+event.oris_id+"&class="+entry.class_id :
          ORIS_URL + "PrehledPrihlasenych?id="+event.oris_id+"&class="+entry.class_id )
        return (
          <>
            <p className="mb-0">
              {event.type !== 2 && <>
                {event.type === 1 ? <>Kategorie: {entry.class_name}</> :
                  <>Kategorie: <Alert.Link href={url}>{entry.class_name}</Alert.Link></>
                } &nbsp;&nbsp;
              </>
              }
              Čip: {entry.si_number}
            </p>
            {(entry.start_time != null) && <p className="mb-0 mt-1">Start: <b>{entry.start_time}</b></p>}
          </>
        );
      }

      if (entry?.id != null) {
        const isReserve = entry?.reserve_idx > 0;
        return (<><Alert variant={selectEnrollVariant(event)}>
          <Row xs="auto">
            <Col>
              <b className="mb-2"><FontAwesomeIcon icon={faCircleCheck}/> {isReserve ? "Náhradník #" + entry?.reserve_idx : "Přihlášeno"}</b>
            </Col>
            <Col>
              <EnrollDetail event={event}/>
            </Col>
          </Row>
        </Alert>
          {(event.type === 0 && event?.startlists) && <Row>
            <Table responsive borderless className="mb-1">
              <tbody>
              <tr>
                <EventClassLinks eventId={event.oris_id} classId={entry.class_id} className={<FontAwesomeIcon icon={faLink}/>} startlists={event?.startlists} results={event?.results} isTeam={event?.is_relay} />
              </tr>
              </tbody>
            </Table>
          </Row>}
        </>);
      }
    }

    function MyEnroll(){
      return (<>
        <Col xs="auto" className="mb-2 pe-2">
          <EnrollLink />
        </Col>
        {entry?.id != null && (event.transport_handled || entryTransport > 1 || (entryTransport >= 1 && labels.CondMyTransportSelf)) &&
          <Col xs="auto" className="mb-2 pe-2">
            <Link to={`/akce/${event.id}/doprava`}><Button className="tapis-95 keep-all" variant="secondary">Moje doprava</Button></Link>
          </Col>
        }
      </>)
    }

    function EnrollLink() {
      if (entry?.id != null) {
        if (event?.disable_entries)
          return <></>;
        return (<Button href={"/akce/" + event.id + "/prihlaska/"} variant="outline-primary" className="keep-all tapis-95">{labels.LinkEntryEdit}</Button>);
      } else if (event.term >= 0) {
        const entryLabel = event.type === 0 && !event?.is_relay ? labels.LinkEntryRemoteCreate : labels.LinkEntryLocalCreate;
        if (entryLabel === "")
          return <></>;
        return (<Button href={"/akce/" + event.id + "/prihlaska/"} disabled={event?.disable_entries} variant={event.term === 0 ? "info" : "primary"}
                        className="tapis-95 keep-all">{entryLabel}</Button>);
      }
      return (<i className="keep-all">Přihlašování skončilo</i>);
    }

    function EnrollOthers() {
      if (event.term >= 0) {
        return (
          <Col xs="auto" className="mb-2">
            <Link to="#" onClick={showEnrollList} className="keep-all">{event.type === 0 && !event?.is_relay ? labels.LinkEntryOthersRemote : labels.LinkEntryOthersLocal}</Link>
          </Col>
        );
      }
    }

    function Services() {
      return <>
        {(event.services > 0 && labels.LinkServices) && <Col xs="auto" className="mb-2">
          <Link to={`/akce/${event.id}/sluzby`} className="keep-all">{labels.LinkServices} ({event.services})</Link>
        </Col>}
      </>
    }

    function EnrollTerms() {
      if (event.term_first == null) {
        if (event.term_preliminary != null)
          return <p><i>Předběžně do: {parseDateTimeSec(event.term_preliminary)}</i></p>
        return;
      }
      if (event.term === 4)
        return <p><i>Běžné přihlášky ukončeny</i></p>;
      if (event.term_manual != null)
        return <p title={"1. termín ORIS: " + parseDateTimeSec(event.term_first)}>Přihlášky v klubu do: {parseDateTimeSec(event.term_manual)}</p>;
      if (event.term_second == null && event.term_third == null) {
        return <p>Přihlášky do: {parseDateTimeSec(event.term_first)}</p>;
      } else if (event.term_second != null && event.term_third == null) {
        return (<>
          <p>1. termín přihlášek: {parseDateTimeSec(event.term_first)} <br />
            2. termín přihlášek: {parseDateTimeSec(event.term_second)}</p>
        </>);
      } else {
        return (<>
          <p>1. termín přihlášek: {parseDateTimeSec(event.term_first)}<br />
            2. termín přihlášek: {parseDateTimeSec(event.term_second)}<br />
            3. termín přihlášek: {parseDateTimeSec(event.term_third)}</p>
        </>);
      }
    }
  }
}

export default EventEnrollCard;
