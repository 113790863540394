function SelectOptionsArray({options}){
  const res = [];
  for (const i in options) {
    const itemKey = Object.keys(options[i])[0];
    res.push(<option key={i} value={itemKey}>{options[i][itemKey]}</option>);
  }
  return res;
}

function SelectOptionsDict({options}){
  const res = [];
  for (const i in options) {
    res.push(<option key={i} value={i}>{options[i]}</option>);
  }
  return res;
}

export {SelectOptionsArray, SelectOptionsDict};