import {faCircle, faLeaf, faSnowflake} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Col, Row} from 'react-bootstrap';
import {ReactComponent as FootR} from '../../static/footR.svg';
import {ReactComponent as FootL} from '../../static/footL.svg';
import {ReactComponent as ControlPoint} from '../../static/control.svg';
import {Theme} from "../../helpers/Constants";

const Loader = () => {
  if (Theme === 'PGP')
    return <ControlIcon />;
  const date = new Date();
  if (date.getMonth() >= 9 && date.getHours() >= 7 && date.getHours() < 19)
    return <FallingLeaves />;
  if (date.getMonth() < 3)
    return <WinterLoader />;
  return <Footprints />;
};

function ControlIcon() {
  return (
    <div className="loader-container" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <ControlPoint className="spin-pulse" style={{
        height: "9em",
        width: "9em",
      }} />
    </div>
  )
}

function WinterLoader() {
  const SNOW_ITEMS_COUNT = 30;

  // We can use two icons to simulate "snowflake" and "snowball"
  const icons = [faSnowflake, faCircle];

  /**
   * Helper to get a random float between min and max
   */
  const getRandomFloat = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  /**
   * We'll space out the snow items evenly across the middle third.
   * That third is roughly from 35% to 66% of the screen width.
   * So each item i in [0..23] gets a left offset in that range.
   */
  const MIN_LEFT = 20;
  const MAX_LEFT = 80;
  const HORIZONTAL_SPAN = MAX_LEFT - MIN_LEFT;

  // Build an array of 24 elements with random icon, random size,
  // evenly spaced left positions, and random animation delay to spread out the fall.
  const snowArray = new Array(SNOW_ITEMS_COUNT).fill(null).map((_, i) => {
    const icon = icons[Math.floor(Math.random() * icons.length)];
    const size = getRandomFloat(1.5, 3);

    // Evenly distribute left
    const left = MIN_LEFT + (i * HORIZONTAL_SPAN) / (SNOW_ITEMS_COUNT - 1);
    const delay = getRandomFloat(0, 4).toFixed(2) - 2;
    let rotate = 0;
    while (rotate === 0)
      rotate = getRandomFloat(-5, 5);
    return { icon, size, left, delay, rotate };
  });

  return (
    <div className="winter-snow-container">
      {snowArray.map((item, index) => (
        <FontAwesomeIcon
          key={index}
          icon={item.icon}
          className="snow"
          style={{
            fontSize: `${item.icon === faCircle ? item.size * 0.8 : item.size}em`,
            left: `${item.left}%`,
            animationDelay: `${item.delay}s`,
            rotate: `${item.rotate}deg`
          }}
        />
      ))}
    </div>
  );
}

function FallingLeaves() {
  const leavesArray = new Array(15).fill(null);  // Create 10 leaves

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  return (
    <div className="falling-leaves-container">
      {leavesArray.map((_, index) => (
        <FontAwesomeIcon icon={faLeaf} className={getRandomInt(3) === 0 ? "leaf center" : (getRandomInt(2) === 0 ? "leaf upper" : "leaf lower")} key={index}/>
      ))}
    </div>
  );
}

function Footprints() {
  return (
    <div className="loader-container" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div>
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <FootL style={{
              height: "2.3em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '1.1s'
            }} />
          </Col>
          <Col style={{ paddingLeft: 0, paddingTop: '15px' }}>
            <FootR style={{
              height: "2em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.95s'
            }} />
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <FootL style={{
              height: "2.3em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.8s'
            }} />
          </Col>
          <Col style={{ paddingLeft: 0, paddingTop: '15px' }}>
            <FootR style={{
              height: "2em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.6s'
            }} />
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <FootL style={{
              height: "2.3em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.4s'
            }} />
          </Col>
          <Col style={{ paddingLeft: 0, paddingTop: '15px' }}>
            <FootR style={{
              height: "2em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.2s'
            }} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Loader;
