import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useParams} from "react-router-dom";
import {deleteEnrollAnonym, getCookie, getEnrollAnonymInfo, sendCampAnonymEnroll} from "../../dao";
import React, { useState, useEffect} from 'react';
import {SelectOptionsDict} from "../../components/form/SelectOptions";
import { useNavigate } from "react-router-dom";
import {faCircleInfo, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {handleErrorLoading, handleErrorSubmit, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import {InterestExtendedOptions, PunchingOptions} from "../../helpers/Constants";
import Loader from "../../components/overlays/Loader";
import InputGroup from "react-bootstrap/InputGroup";
import FormField from "../../components/form/FormField";
import PageContainerAnonym from "../../layout/PageContainerAnonym";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Modal from "react-bootstrap/Modal";
import EventInformationCard from "../../components/parts/EventInformationCard";
import EventMap from "../../components/parts/EventMap";
import Alert from "react-bootstrap/Alert";
import AutoFormat from "../../components/visual/AutoFormat";
import CampEnrollFormSection from "../../components/parts/CampEnrollFormSection";
import {labels} from "../../themeLabels";

const EnrollCampAnonym = () => {
  const { eventId } = useParams();
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [successAdd, setSuccessAdd] = useState("");
  const [successEdit, setSuccessEdit] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [successDelete, setSuccessDelete] = useState("");
  const [targetId, setTargetId] = useState(null);
  const [data, setData] = useState([]);
  const [form, setForm] = useState([]);
  const navigate = useNavigate();

  const [siNumber, setSiNumber] = useState("");
  const [transport, setTransport] = useState(0);
  const [note, setNote] = useState("");

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");

  useEffect(() => {
    loadEnrollInfo();
  }, [userId, eventId]);

  function loadEnrollInfo(){
    setLoading(true);
    getEnrollAnonymInfo(eventId, userId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()})
      .then((response) => {
        setData(response);
        setForm(response?.detail?.form);
        if (response?.event?.transport === 1) {
          setTransport(1);
        }
        if (userId != null) {
          setSiNumber(response?.detail?.submission?.si_number);
          setTransport(response?.detail?.submission?.transport);
          setNote(response?.detail?.submission?.note);

          setName(response?.user?.name);
          setSurname(response?.user?.surname);
          setRegNumber(response?.user?.reg_number);
          setBirthdate(response?.user?.birthdate);
          setStreet(response?.user?.street);
          setCity(response?.user?.city);
          setZipCode(response?.user?.zip_code);
          setEmail(response?.user?.mail1);
        } else {
          if (data?.event?.term < 0)
            setErrorRemote("Přihlášky nejsou otevřeny...");
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    if(!checkRequiredFields()){
      setError("Nevyplněna všechna povinná pole");
      return;
    }
    const mode = userId != null ? "PUT" : "POST";
    const userData = {"name": name, "surname": surname, "reg_number": regNumber, "birthdate": birthdate, "street": street, "city": city, "zip_code": zipCode, "mail1": email};

    e.preventDefault();
    getCookie()
      .then((res) => {
        sendCampAnonymEnroll(eventId, userId, userData, form, siNumber, transport, note, mode, res.headers.get("X-Csrftoken"))
          .then((response) => {
            if(response.ok){
              (mode === "POST") ? setSuccessAdd("Přihláška přidána") : setSuccessEdit("Přihláška upravena");
              return response.json();
            }else{
              setError(handleErrorSubmit(response, (mode === 'POST' ? "Nepodařilo se přihlásit" : "Nepodařilo se upravit přihlášku")));
              return []
            }
          })
          .then((r) => {
            setTargetId(r?.user?.id);
          })
      })
  };

  function checkRequiredFields(){
    let result = true;
    if (!name) result = false;
    if (!surname) result = false;
    if (!email) result = false;

    form.forEach((field)=> {
      let invalid = false;
      if (field.required) {
        if (!field.closed_ended) {
          if (isEmpty(field.value)) {
            invalid = true;
          }
        } else {
          let isAnythingSelected = false;
          if (field.multichoice) // multiselect
            field.choices.forEach((choice) => {isAnythingSelected = (choice.checked ? true : isAnythingSelected)});
          else // single-select
            field.choices.forEach((choice) => {isAnythingSelected = (choice.id === field.selected ? true : isAnythingSelected)});
          if (field?.selected === 0 && !isEmpty(field?.value))
            isAnythingSelected = true;
          if (!isAnythingSelected) {
            invalid = true;
          }
        }
      }
      field.invalid = invalid;
      if (invalid)
        result = false;
    });
    return result;
  }

  function isEmpty(item){
    return typeof item === "undefined" || item == null || item === "";
  }

  const CreatorEditorText = ({person, label}) => {
    const labelText = label + ": ";
    if (person == null)
      return "";
    return <i>{labelText} {person.reg_number} – {person.full_name}</i>;
  }

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Přihláška | ' + data?.event?.title)
  return (
    <PageContainerAnonym background={getBackground()}>
      <h1>Jednorázová přihláška: {data?.event?.title}</h1>
      <Row style={{margin: "auto"}}>
        <Alert variant="warning">Jednorázová přihláška je určena pouze pro osoby, které nemají svůj účet v {labels.SystemShortName}u</Alert>
      </Row>
      <Row style={{margin: "auto"}}><EventInfo /></Row>
      <Row>
        <Col md><EventInformationCard event={data?.event} /></Col>
        <Col md><EventMap event={data?.event} fixedHeight={'200px'} /></Col>
      </Row>
      <Row>
        <Col><p className="mb-2 text-end">Přihlášky do: {parseDateTime(data?.event?.term_first)}</p></Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md>
            <Form.Group className="mb-3" controlId="blankUser">
              <Form.Label column={false}>Jméno | Příjmení:</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text" style={{width: '50%'}} disabled={data.event.term < 0} isInvalid={name === ""} isValid={name !== ""}
                  value={name} onChange={(e) => setName(e.target.value)}/>
                <Form.Control
                  type="text" style={{width: '50%'}} disabled={data.event.term < 0} isInvalid={surname === ""} isValid={surname !== ""}
                  value={surname} onChange={(e) => setSurname(e.target.value)}/>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm>
            <FormField label={"Registrační číslo"} value={regNumber} setValue={setRegNumber} valueLimit={7} digits={"none"} disabled={data.event.term < 0} isValid={regNumber !== ""} controlId={"regNumber"} />
          </Col>
          <Col sm>
            <FormField label={"Datum narození"} type={"date"} value={birthdate} setValue={setBirthdate} disabled={data.event.term < 0} isInvalid={data?.event?.date_to != null && birthdate === ""} controlId={"birthdate"} />
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormField label={"Ulice a ČP"} value={street} setValue={setStreet} valueLimit={128} digits={"none"} disabled={data.event.term < 0 || data?.event?.date_to == null} isInvalid={data?.event?.date_to != null && street === ""} controlId={"street"} />
          </Col>
          <Col sm>
            <FormField label={"Město"} value={city} setValue={setCity} valueLimit={64} digits={"none"} disabled={data.event.term < 0 || data?.event?.date_to == null} isInvalid={data?.event?.date_to != null && city === ""} controlId={"city"} />
          </Col>
          <Col sm>
            <FormField label={"PSČ"} value={zipCode} setValue={setZipCode} valueLimit={8} digits={"none"} disabled={data.event.term < 0 || data?.event?.date_to == null} isInvalid={data?.event?.date_to != null && zipCode === ""} controlId={"zipCode"} />
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormField label={"Email"} value={email} setValue={setEmail} valueLimit={64} digits={"none"} disabled={data.event.term < 0} isInvalid={email === ""} isValid={email !== "" && email.includes('@')} controlId={"email"} />
          </Col>
          <Col md>
            <Form.Group className="mb-3" controlId="si">
              <Row className="mb-2">
                <Col xs="auto">Čip:</Col>
                <Col className="text-end">Ražení: {PunchingOptions[data?.event?.punching]}</Col>
              </Row>
              <FormField label={""} value={siNumber} setValue={setSiNumber} valueLimit={8} disabled={data.event.term < 0} controlId={"siNumber"} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="transport">
              <Form.Label column={false}>Společná doprava:</Form.Label>
              <InputGroup>
                <Form.Select className="tapis-95" disabled={data?.event?.transport !== 0 || data.event.term < 0}
                             value={transport} onChange={(e) => setTransport(Number(e.target.value))}>
                  <SelectOptionsDict options={InterestExtendedOptions}/>
                </Form.Select>
              </InputGroup>
            </Form.Group>
            {data?.event?.transport_info !== "" && <><FontAwesomeIcon icon={faCircleInfo} color="gray"/> <i>{data?.event?.transport_info}</i></>}
          </Col>
        </Row>
        <hr/>
        <h2>Formulář pro účastníky</h2>
        <p className="mb-4"><i>Pole s hvězdičkou jsou povinná</i></p>
        <div>
          <CampEnrollFormSection form={form} setForm={setForm} note={note} setNote={setNote}/>
        </div>
        {userId == null && <p>Po odeslání přihlášky tě systém přesměruje, do emailu ti přijde odkaz pro možnost úprav.</p>}
        <Row>
          {data?.applied &&
            <Col md className="mb-2">
              <CreatorEditorText person={data.detail.submission.created_by} label={"Vytvořil/a"}/><br/>
              <CreatorEditorText person={data.detail.submission.edited_by} label={"Upravil/a"}/>
            </Col>
          }
          <Col className="text-end">
            <EnrollDelete/>
            <EnrollSubmit/>
          </Col>
        </Row>
      </Form>
      <br/>
      <Modal show={deleteConfirm} onHide={()=> {setDeleteConfirm(false)}}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce /> &nbsp;
          <Modal.Title>Skutečně smazat přihlášku?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=> {
            setDeleteConfirm(false);
          }}>Zavřít</Button>
          <Button variant="primary" onClick={tryDeleteEnroll}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={successAdd} handleClose={() => {
        setSuccessAdd("");
        navigate("/anonym/akce/" + eventId + "/prihlaska/" + targetId)
      }}/>
      <SuccessAlert title={successEdit} handleClose={() => {setSuccessEdit("")}}/>
      <SuccessAlert title={successDelete} handleClose={() => {
        setSuccessDelete("");
        navigate("/anonym/akce/" + eventId + "/prihlaska/")
      }}/>
    </PageContainerAnonym>
  );

  function EventInfo(){
    if(data?.event?.event_info != null && data?.event?.event_info !== ""){
      return(<Alert variant="info"><AutoFormat text={data?.event.event_info}/></Alert>);
    }
  }

  function EnrollSubmit(){
    if(data?.applied)
      return <Button className="tapis-95" style={{"marginLeft": "10px"}} variant="primary" type="submit">{labels.ButtonEntryUpdate}</Button>;
    return <Button variant="primary" type="submit">Přihlásit</Button>;
  }

  function EnrollDelete(){
    if(data?.applied && data.event.term > 0){
      return(<Button className="tapis-95" variant="secondary" onClick={() => setDeleteConfirm(true)}>Smazat přihlášku</Button>);
    }
  }

  function tryDeleteEnroll(){
    getCookie()
      .then((res) => {
        deleteEnrollAnonym(eventId, userId, res.headers.get("X-Csrftoken"))
          .then((response) => {
            if (response.ok)
              setSuccessDelete("Přihláška smazána");
            else
              setError(handleErrorSubmit(response, "Nepodařilo se smazat"));
            setDeleteConfirm(false);
          })
      })

  }

  function getBackground(){
    if(data?.applied)
      return "rgba(2,200,30,0.2)";
    return "rgba(0,0,0,0)";
  }
};

export default EnrollCampAnonym;