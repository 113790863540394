import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import FormField from "../form/FormField";
import InputGroup from "react-bootstrap/InputGroup";
import React from "react";


const CampEnrollFormSection = ({form, setForm, note, setNote, showNote=true}) => {
  function handleCheckboxChange(e, choiceId, fieldId){
    let tempForm = JSON.parse(JSON.stringify(form));
    for(let fieldIndex in tempForm){
      if(tempForm[fieldIndex].id === fieldId){
        for (let choiceIndex in tempForm[fieldIndex].choices){
          if(tempForm[fieldIndex].choices[choiceIndex].id === choiceId){
            tempForm[fieldIndex].choices[choiceIndex].checked = !tempForm[fieldIndex].choices[choiceIndex].checked;
          }
        }
        if (choiceId === 0)
          tempForm[fieldIndex].selected = (tempForm[fieldIndex]?.selected === 0 ? -1 : 0);
        tempForm[fieldIndex].invalid = false;
      }
    }
    setForm(tempForm);
  }
  function handleRadioChange(e, choiceId, fieldId){
    let tempForm = JSON.parse(JSON.stringify(form));
    for(let fieldIndex in tempForm){
      if(tempForm[fieldIndex].id === fieldId){
        tempForm[fieldIndex].selected = choiceId;
        tempForm[fieldIndex].invalid = false;
      }
    }
    setForm(tempForm);
  }
  function setFieldValue(fieldId, value){
    let tempForm = JSON.parse(JSON.stringify(form));
    for(let fieldIndex in tempForm){
      if(tempForm[fieldIndex].id === fieldId){
        tempForm[fieldIndex].value = value;
        tempForm[fieldIndex].invalid = false;
      }
    }
    setForm(tempForm);
  }

  function CampEnrollForm(form, note, setNote) {
    const CampField = (field) => {
      let invalid = field?.invalid == null ? false : field?.invalid;
      if (!field.closed_ended) {
        return <Form.Control value={field.value} isInvalid={invalid} onChange={(e)=>{setFieldValue(field.id, e.target.value)}}/>;
      } else {
        const res = [];
        for (let choiceId in field.choices) {
          let choice = field.choices[choiceId];
          if (field.multichoice) {
            res.push(<Form.Check type="checkbox" label={choice.value} key={"c-"+choice.id} isInvalid={invalid} checked={choice.checked}
                                 onChange={(e) => handleCheckboxChange(e, choice.id, field.id)}/>);
          } else {
            res.push(<Form.Check type="radio" label={choice.value} key={"c-"+choice.id} isInvalid={invalid} checked={field.selected === choice.id}
                                 onChange={(e) => handleRadioChange(e, choice.id, field.id)}/>)
          }
        }
        if (field.other_choice) {
          res.push(
            <InputGroup key={0}>
              <Form.Check type={field.multichoice ? "checkbox" : "radio"} label={"Jiné: "} checked={field.selected === 0} isInvalid={invalid}
                          onChange={(e) => {
                            field.multichoice ? handleCheckboxChange(e, 0, field.id) : handleRadioChange(e, 0, field.id)}}/>
              <Form.Control style={{marginLeft: "1em"}} value={field.value} disabled={field?.selected !== 0} isInvalid={invalid}
                            onChange={(e) => {setFieldValue(field.id, e.target.value)}}/>
            </InputGroup>
          );
        }
        return res;
      }
    }

    const res = [];
    for (const fieldId in form) {
      const field = form[fieldId];
      res.push(
        <Row key={"r"+field.id} className="mb-1" style={{marginLeft: "6%", marginRight: "6%"}}>
          <Form.Group>
            <Form.Label column={true}><b>{field.index + ". " + field.title}{field.required && <i style={{color: "red"}}>*</i>}</b></Form.Label>
            {field.note !== "" && <p className="ms-2 mt-1 mb-1"><i>{field.note}</i></p>}
            <div className="ms-2 mt-2 mb-4" key={"q-"+field.id}>
              {CampField(field)}
            </div>
            <hr/>
          </Form.Group>
        </Row>
      );
    }
    if (showNote) {
      res.push(
        <Row key={"r0"} className="mb-3" style={{marginLeft: "6%", marginRight: "6%"}}>
          <FormField label={"Poznámka"} value={note} setValue={setNote} valueLimit={128} controlId={"noteAfterForm"} />
          <br/>
        </Row>);
    }
    return <div className="mb-3">
      {res}
    </div>
  }

  return CampEnrollForm(form, note, setNote);
}

export default CampEnrollFormSection;