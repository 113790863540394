import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {deleteEnrollAnonym, getCookie, sendAnonymEnroll} from "../../dao";
import React, { useState, useEffect} from 'react';
import {SelectOptionsDict} from "../../components/form/SelectOptions";
import { useNavigate } from "react-router-dom";
import {faCircleInfo, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {classDict, getClassName, handleErrorSubmit, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import {HomeClub, InterestExtendedOptions, PunchingOptions} from "../../helpers/Constants";
import InputGroup from "react-bootstrap/InputGroup";
import FormField from "../../components/form/FormField";
import PageContainerAnonym from "../../layout/PageContainerAnonym";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Modal from "react-bootstrap/Modal";
import EventInformationCard from "../../components/parts/EventInformationCard";
import EventMap from "../../components/parts/EventMap";
import Alert from "react-bootstrap/Alert";
import AutoFormat from "../../components/visual/AutoFormat";
import {labels} from "../../themeLabels";
import FormSelectDict from "../../components/form/FormSelectDict";

const EnrollTrainingAnonym = ({eventId, userId, data}) => {
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [successAdd, setSuccessAdd] = useState("");
  const [successEdit, setSuccessEdit] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [successDelete, setSuccessDelete] = useState("");
  const [targetId, setTargetId] = useState(null);
  const navigate = useNavigate();

  const [classId, setClassId] = useState("");
  const [siNumber, setSiNumber] = useState("");
  const [transport, setTransport] = useState(0);
  const [clubNote, setClubNote] = useState("");

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (data?.event?.transport === 1) {
      setTransport(1);
    }
    if (userId != null) {
      setClassId(data?.detail?.class_id+"");
      setSiNumber(data?.detail?.si_number);
      setTransport(data?.detail?.transport);
      setClubNote(data?.detail?.club_note);

      setName(data?.user?.name);
      setSurname(data?.user?.surname);
      setRegNumber(data?.user?.reg_number);
      setEmail(data?.user?.mail1);
    } else {
      setClassId(data?.user?.classes.length > 0 ? data?.user?.classes[0].id : "");
      if (data?.event?.term < 0)
        setErrorRemote("Přihlášky nejsou otevřeny...");
    }
  }, [userId, eventId]);


  let handleSubmit = async (e) => {
    e.preventDefault();
    if(!checkRequiredFields()){
      setError("Nevyplněna všechna povinná pole");
      return;
    }
    const mode = userId != null ? "PUT" : "POST";
    const userData = {"name": name, "surname": surname, "reg_number": regNumber, "mail1": email};
    const formData = {
      "person" : data.user.id,
      "user_data": userData,
      "class_id" : classId,
      "class_name" : getClassName(classId, data.user.classes),
      "si" : siNumber,
      "rent_si" : siNumber === "",
      "club_note" : clubNote,
      "transport" : transport,
      "accommodation": 0
    }

    e.preventDefault();
    getCookie()
      .then((res) => {
        sendAnonymEnroll(eventId, userId, formData, mode, res.headers.get("X-Csrftoken"))
          .then((response) => {
            if(response.ok){
              (mode === "POST") ? setSuccessAdd("Přihláška přidána") : setSuccessEdit("Přihláška upravena");
              return response.json();
            }else{
              setError(handleErrorSubmit(response, (mode === 'POST' ? "Nepodařilo se přihlásit" : "Nepodařilo se upravit přihlášku")));
              return []
            }
          })
          .then((r) => {
            setTargetId(r?.user?.id);
          })
      })
  };

  function checkRequiredFields(){
    let result = true;
    if (!name) result = false;
    if (!surname) result = false;
    if (!email) result = false;
    return result;
  }

  const CreatorEditorText = ({person, label}) => {
    const labelText = label + ": ";
    if (person == null)
      return "";
    return <i>{labelText} {person.reg_number} – {person.full_name}</i>;
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Přihláška | ' + data?.event?.title)
  return (
    <PageContainerAnonym background={getBackground()}>
      <h1>Jednorázová přihláška: {data?.event?.title}</h1>
      <Row style={{margin: "auto"}}>
        <Alert variant="warning">Jednorázová přihláška je určena pouze pro osoby, které nemají svůj účet v {labels.SystemShortName}u</Alert>
      </Row>
      <Row style={{margin: "auto"}}><EventInfo /></Row>
      <Row>
        <Col md><EventInformationCard event={data?.event} /></Col>
        <Col md><EventMap event={data?.event} fixedHeight={'200px'} /></Col>
      </Row>
      <Row>
        <Col><p className="mb-2 text-end">Přihlášky do: {parseDateTime(data?.event?.term_first)}</p></Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm>
            <FormField label={"Jméno"} value={name} setValue={setName} valueLimit={64} digits={"none"} disabled={data.event.term < 0}
                       isInvalid={name === ""} isValid={name !== ""} controlId={"name"} />
          </Col>
          <Col sm>
            <FormField label={"Příjmení"} value={surname} setValue={setSurname} valueLimit={64} digits={"none"} disabled={data.event.term < 0}
                       isInvalid={surname === ""} isValid={surname !== ""} controlId={"surname"} />
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <FormField label={"Email"} value={email} setValue={setEmail} valueLimit={64} digits={"none"} disabled={data.event.term < 0}
                       isInvalid={email === ""} isValid={email !== "" && email.includes('@')} controlId={"email"} />
          </Col>
          <Col sm>
            <FormField label={"Registrační číslo"} value={regNumber} setValue={setRegNumber} valueLimit={7} digits={"none"} disabled={data.event.term < 0}
                       placeholder={"(pouze pokud máš)"} controlId={"regNumber"} />
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormSelectDict label={"Kategorie"} value={classId} setValue={setClassId} options={classDict(data?.user?.classes, data?.event?.type)}
                            disabled={data?.applied && (!data.detail?.delete_person)} controlId={"class"} />
          </Col>
          <Col md>
            <Form.Group className="mb-3" controlId="si">
              <Row className="mb-2">
                <Col xs="auto">Čip:</Col>
                <Col className="text-end">Ražení: {PunchingOptions[data?.event?.punching]}</Col>
              </Row>
              <FormField label={""} value={siNumber} setValue={setSiNumber} valueLimit={8} disabled={data.event.term < 0} controlId={"siNumber"} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="transport">
              <Form.Label column={false}>Společná doprava:</Form.Label>
              <InputGroup>
                <Form.Select className="tapis-95" disabled={data?.event?.transport !== 0 || data.event.term < 0}
                             value={transport} onChange={(e) => setTransport(Number(e.target.value))}>
                  <SelectOptionsDict options={InterestExtendedOptions}/>
                </Form.Select>
              </InputGroup>
            </Form.Group>
            {data?.event?.transport_info !== "" && <><FontAwesomeIcon icon={faCircleInfo} color="gray"/> <i>{data?.event?.transport_info}</i></>}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField label={"Poznámka " + HomeClub} value={clubNote} setValue={setClubNote} valueLimit={256} controlId={"clubNote"} />
          </Col>
        </Row>
        <hr/>
        {userId == null && <p>Po odeslání přihlášky tě systém přesměruje, do emailu ti přijde odkaz pro možnost úprav.</p>}
        <Row>
          {data?.applied &&
            <Col md className="mb-2">
              <CreatorEditorText person={data.detail.created_by} label={"Vytvořil/a"}/><br/>
              <CreatorEditorText person={data.detail.edited_by} label={"Upravil/a"}/>
            </Col>
          }
          <Col className="text-end">
            <EnrollDelete/>
            <EnrollSubmit/>
          </Col>
        </Row>
      </Form>
      <br/>
      <Modal show={deleteConfirm} onHide={()=> {setDeleteConfirm(false)}}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce /> &nbsp;
          <Modal.Title>Skutečně smazat přihlášku?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=> {
            setDeleteConfirm(false);
          }}>Zavřít</Button>
          <Button variant="primary" onClick={tryDeleteEnroll}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={successAdd} handleClose={() => {
        setSuccessAdd("");
        navigate("/anonym/akce/" + eventId + "/prihlaska/" + targetId)
      }}/>
      <SuccessAlert title={successEdit} handleClose={() => {setSuccessEdit("")}}/>
      <SuccessAlert title={successDelete} handleClose={() => {
        setSuccessDelete("");
        navigate("/anonym/akce/" + eventId + "/prihlaska/")
      }}/>
    </PageContainerAnonym>
  );

  function EventInfo(){
    if(data?.event?.event_info){
      return(<Alert variant="info"><AutoFormat text={data?.event.event_info}/></Alert>);
    }
  }

  function EnrollSubmit(){
    if(data?.applied)
      return <Button className="tapis-95" style={{"marginLeft": "10px"}} variant="primary" type="submit">{labels.ButtonEntryUpdate}</Button>;
    return <Button variant="primary" type="submit">Přihlásit</Button>;
  }

  function EnrollDelete(){
    if(data?.applied && data.event.term > 0){
      return(<Button className="tapis-95" variant="secondary" onClick={() => setDeleteConfirm(true)}>Smazat přihlášku</Button>);
    }
  }

  function tryDeleteEnroll(){
    getCookie()
      .then((res) => {
        deleteEnrollAnonym(eventId, userId, res.headers.get("X-Csrftoken"))
          .then((response) => {
            if (response.ok)
              setSuccessDelete("Přihláška smazána");
            else
              setError(handleErrorSubmit(response, "Nepodařilo se smazat"));
            setDeleteConfirm(false);
          })
      })

  }

  function getBackground(){
    if(data?.applied)
      return "rgba(2,200,30,0.2)";
    return "rgba(0,0,0,0)";
  }
};

export default EnrollTrainingAnonym;