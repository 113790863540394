import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {editEvent} from "../../dao";
import React, { useState, } from 'react';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {
  DisciplineBasicOptions,
  PunchingOptions,
  TernaryOptionsNeutral,
  TernaryOptionsTransport
} from "../../helpers/Constants";
import Modal from "react-bootstrap/Modal";
import {handleErrorSubmit} from "../../helpers/Functions";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import useUserData from "../../helpers/Auth";
import FormCoordinates from "../../components/form/FormCoordinates";
import MapPositionSelect from "../../components/parts/MapPositionSelect";
import FormCheckbox from "../../components/form/FormCheckbox";
import FormFieldCategory from "../../components/form/FormFieldCategory";
import CategoryEditor from "../../components/parts/CategoryEditor";
import EventClubOffersEdit from "../../components/parts/EventClubOffersEdit";
import DeleteEventZone from "../../components/parts/DeleteEventZone";
import {labels} from "../../themeLabels";

const EditTraining = ({eventId, event}) => {
  const { userData, setUserData } = useUserData();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [editCategories, setEditCategories] = useState(false);

  const [title, setTitle] = useState(event.title);
  const [date, setDate] = useState(event.date);
  const [place, setPlace] = useState(event.place);

  const [info, setInfo] = useState(event.info);
  const [eventInfo, setEventInfo] = useState(event.event_info);
  const [eventWarning, setEventWarning] = useState(event.event_warning);

  const [termFirst, setTermFirst] = useState(event.term_first);
  const [allowLate, setAllowLate] = useState(event.allow_late);
  const [allowAnonymous, setAllowAnonymous] = useState(event?.allow_anonym);
  const [start, setStart] = useState(event.start);
  const [visible, setVisible] = useState(event.visible);

  const [classData, setClassData] = useState(event?.class_data);
  const [discipline, setDiscipline] = useState(event.discipline);
  const [organiser, setOrganiser] = useState(event.organiser);
  const [punching, setPunching] = useState(event.punching);

  const [GPSlong, setGPSlong] = useState(event?.GPS_longitude !== undefined ? event?.GPS_longitude : '');
  const [GPSlat, setGPSlat] = useState(event?.GPS_latitude !== undefined ? event?.GPS_latitude : '');

  const [transport, setTransport] = useState(event.transport);
  const [transportInfo, setTransportInfo] = useState(event.transport_info);

  const [accommodation, setAccommodation] = useState(event.accommodation);
  const [accommodationInfo, setAccommodationInfo] = useState(event.accommodation_info);

  let handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      "title": title,
      "date": date,
      "place" : place,
      "info": info,
      "event_info": eventInfo,
      "event_warning": eventWarning,
      "visible": visible,
      "term_first": termFirst,
      "allow_late" : allowLate,
      "allow_anonym": allowAnonymous,
      "start": start,
      "discipline": discipline,
      "organiser": organiser,
      "punching": punching,
      "class_data" : classData,
      "transport": transport,
      "transport_info": transportInfo,
      "accommodation": accommodation,
      "accommodation_info": accommodationInfo,
      "GPS_longitude": GPSlong,
      "GPS_latitude": GPSlat
    }
    const res = await editEvent(eventId, formData);
    if (res.ok)
      setSuccess("Akce upravena");
    else
      setError(handleErrorSubmit(res, "Nepodařilo se upravit"));
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md>
            <FormField label={"Název"} value={title} setValue={setTitle} valueLimit={512} controlId={"title"} />
          </Col>
          <Col md>
            <FormField label={"Datum"} type="date" value={date} setValue={setDate} controlId={"date"} />
          </Col>
          <Col md>
            <FormField label={"Termín přihlášek"} type={"datetime-local"} value={termFirst} setValue={setTermFirst} controlId={"term"} />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <FormField label={"Místo"} value={place} setValue={setPlace} valueLimit={512} controlId={"place"}/>
          </Col>
          <Col lg>
            <FormCoordinates GPSlat={GPSlat} GPSlong={GPSlong} setGPSlat={setGPSlat} setGPSlong={setGPSlong} setShowMap={setShowMap}/>
          </Col>
          <Col lg>
            <FormField label={"Start (hh:mm:ss)"} value={start} setValue={setStart} valueLimit={8} digits={"none"} controlId={"start"}/>
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormCheckbox label={"Viditelná pro běžné uživatele"} value={visible} setValue={setVisible} controlId={"visible"}
                          tooltip={"Viditelnou akci vidí v kalendáři všichni uživatelé, skrytou pouze uživatelé s právem na úpravy akcí po přepnutí filtru Zrušené a skryté na Ano"}/>
          </Col>
          <Col md>
            <FormCheckbox label={"Jednorázové přihlášky možné"} value={allowAnonymous} setValue={setAllowAnonymous} controlId={"allowAnonymous"}
                          tooltip={"Na akci se mohou speciálním formulářem přihlašovat i lidé bez přístupu do systému, po vyplnění obdrží email s odkazem pro úpravy"}/>
          </Col>
          <Col md>
            {userData.administrate && <FormCheckbox label={"Přihlášky trenérů po termínu"} value={allowLate} setValue={setAllowLate} controlId={"allowLate"}
                                                    tooltip={"Lidé s právem přihlašovat všechny mohou přidávat a měnit přihlášky i po skončení termínu"}/>}
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col lg>
            <FormField label={"Info text"} value={info} setValue={setInfo} valueLimit={2048} controlId={"info"}
                       help={"Text umístěný na stránce akce v kartě Informace"} />
          </Col>
          <Col lg>
            <FormField label={"Info hláška"} value={eventInfo} setValue={setEventInfo} valueLimit={2048} controlId={"eventInfo"}
                       help={"Text umístěný nahoře na stránce akce na modrém podkladu (styl informace)"} />
          </Col>
          <Col lg>
            <FormField label={"Varovná hláška"} value={eventWarning} setValue={setEventWarning} valueLimit={2048} controlId={"eventWarning"}
                       help={"Text umístěný nahoře na stránce akce na žlutém podkladu (styl varování)"} />
          </Col>
          <hr />
        </Row>
        <Row>
          <Col>
            <FormSelectDict label={"Disciplína"} value={discipline} setValue={setDiscipline} options={DisciplineBasicOptions} controlId={"discipline"} />
          </Col>
          <Col md>
            <FormField label={"Pořadatel"} value={organiser} setValue={setOrganiser} valueLimit={128} controlId={"organiser"} />
          </Col>
          <Col md>
            <FormSelectDict label={"Ražení"} value={punching} setValue={setPunching} options={PunchingOptions} controlId={"punching"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormFieldCategory classData={classData} setEditClasses={setEditCategories} controlId={"categoryEdit"} />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col>
            <FormSelectDict label={"Klubová doprava"} value={transport} setValue={setTransport} options={TernaryOptionsTransport} controlId={"transport"} />
          </Col>
          <Col md={8}>
            <FormField label={"Info o dopravě"} value={transportInfo} setValue={setTransportInfo} disabled={transport === "-1"}  valueLimit={128} controlId={"transportInfo"} />
          </Col>
        </Row>
        {labels.CondEventAccommodation && <Row>
          <Col>
            <FormSelectDict label={"Klubové ubytování"} value={accommodation} setValue={setAccommodation} options={TernaryOptionsNeutral} controlId={"accommodation"} />
          </Col>
          <Col md={8}>
            <FormField label={"Info k ubytování"} value={accommodationInfo} setValue={setAccommodationInfo} disabled={accommodation === "-1"} valueLimit={128} controlId={"accommodationInfo"} />
          </Col>
        </Row>}
        <Row>
          <Col className="col-md-12 text-end">
            <Button variant="primary" type="submit"><b>{labels.ButtonEventUpdate}</b></Button>
          </Col>
        </Row>
        <br/>
      </Form>
      <Row>
        <hr className="mb-1"/>
        <hr className="mt-1"/>
        <EventClubOffersEdit eventId={eventId}/>
      </Row>
      {userData?.administrate && <Row>
        <DeleteEventZone event={event} />
      </Row>}
      <CategoryEditor classData={classData} setClassData={setClassData} editClasses={editCategories} setEditClasses={setEditCategories} />
      <Modal show={showMap} onHide={() => setShowMap(false)}>
        <Modal.Header closeButton>
          <Modal.Body>Vyber místo kliknutím do mapy</Modal.Body>
        </Modal.Header>
        <MapPositionSelect GPSlat={GPSlat} GPSlong={GPSlong} setGPSlat={setGPSlat} setGPSlong={setGPSlong} setShowMap={setShowMap}/>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </>
  );
};

export default EditTraining;